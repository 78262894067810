import { Footer, Header, BannerList, Features, HowTo, CollapseWrapper, Reminder } from "../../components"

import banner from "assets/images/banner-markets.svg"
import bgReady from "../../assets/images/abstract-bg.png"

import img1 from "assets/icons/markets/features1/1.svg"
import img2 from "assets/icons/markets/features1/2.svg"
import img3 from "assets/icons/markets/features1/3.svg"
import img4 from "assets/icons/markets/features1/4.svg"

import img11 from "assets/icons/markets/features2/1.svg"
import img12 from "assets/icons/markets/features2/2.svg"
import img13 from "assets/icons/markets/features2/3.svg"
import img14 from "assets/icons/markets/features2/4.svg"
import img15 from "assets/icons/markets/features2/5.svg"
import img16 from "assets/icons/markets/features2/6.svg"

import table from "../../assets/images/table.svg"

import "./Markets.scss"

export const Markets = (): JSX.Element => {
  const bannerContent = {
    title: "Buy Bitcoin in Seconds",
    text: "Buy BTC quickly аnd securely via card or bank transfer. Start with as little as $10.",
    img: banner,
    list: [
      "Get up to 0.5% back",
      "Buy and start earning right away",
      "Excellent market prices",
      "SEPA, Faster Payments, and SWIFT",
    ],
  }

  const featuresContent1 = [
    {
      title: "Buy Instantly",
      text: "Buy BTC with card in 7 seconds. Secure wire transfers are also accepted.",
      img: img1,
    },
    {
      title: "Start with Just $10",
      text: "Start with as little as $10 or go up to $15,000 – including fees, if any.",
      img: img2,
    },
    {
      title: "Get Excellent Prices",
      text: "Get the most cost-efficient price across the top 10 crypto exchanges worldwide.",
      img: img3,
    },
    {
      title: "Stay Secure",
      text: "Benefit from a top-tier insurance secured by GrowMiner’s trusted custodians.",
      img: img4,
    },
  ]

  const featuresContent2 = [
    {
      title: "Earn Interest",
      text: "Start earning daily compounding interest on your BTC immediately with our Earn on Crypto suite.",
      img: img11,
    },
    {
      title: "Exchange Seamlessly",
      text: "Swap any asset for BTC with zero fees and fixed-price execution on GrowMiner's Crypto Exchange.",
      img: img12,
    },
    {
      title: "Borrow Instantly",
      text: "Borrow funds from 0% APR without selling your BTC with GrowMiner's Instant Crypto Credit Lines.",
      img: img13,
    },
    {
      title: "Spend Without Selling",
      text: "Spend the value of your Bitcoin without selling it, all while getting up to 2% back in crypto rewards.",
      img: img14,
    },
    {
      title: "Boost Your Profits",
      text: "Leverage against your BTC to acquire up to 3x more of your desired crypto with the GrowMiner Booster.",
      img: img15,
    },
    {
      title: "Send Bitcoin",
      text: "Make free, instant BTC transfers to all your friends, family or colleagues as long as they have a GrowMiner account.",
      img: img16,
    },
  ]

  const tableContent = [
    {
      title: "Market Rank",
      text: "$235.25",
    },
    {
      title: "Market Cap",
      text: "$235.25",
    },
    {
      title: "24H Volume",
      text: "$235.25",
    },
    {
      title: "All time High",
      text: "$235.25",
    },
    {
      title: "Circulating Supply",
      text: "$235.25",
    },
  ]

  const faqsContent = [
    {
      title: "What interest rates do you pay?",
      desc: "You receive a flat 7% interest on stablecoins, 20% on Ethereum and 30% on File Coins and it is all yours to keep. Funds can be added and removed in cryptocurrency or fiat currency daily.",
    },
    {
      title: "Do you charge any fees?",
      desc: "When you withdraw funds from the platform there is no fee for fiat withdrawals. There is a $20 for crypto withdrawals, charged in the asset you are withdrawing. What payment methods do you accept? We accept payment by card, bank transfer, or wallet-to-wallet crypto payments (currently accepting BTC,ETH,BNB,USDT, FIL,UNI)\n\n",
    },
    {
      title: "How do you pay such high rates of return?",
      desc: "Growminer generates returns by lending out our customer's digital assets to institutional and retail borrowers as well as participating in and supporting decentralised exchanges. We have a rigorous risk management process and insure deposits that we lend to decentralised exchanges. Where we lend money to retail investors and institutions, we ensure that it is 100%+ collateralised.  This strategy means funds are easily accessible and we pay out returns to customer's daily. Returns generated by cryptocurrency deposits remain high as market participants are willing to pay high levels of interest to borrow assets they struggle to access via traditional needs. As the crypto market develops yields will decrease as capital becomes more accessible, however, we feel there will also be opportunities that emerge to generate yield to compensate.",
    },
    {
      title: "Are you secure?",
      desc: "Cryptocurrencies are inherently high-risk products as blockchain technology is new. The main risks faced by companies within the crypto space are: Hacking – crypto wallets held by Growminer are penetrated by third parties. Growminer uses leading wallet infrastructure provider Fireblocks to ensure the security of assets. We also have a $30 million policy in the event assets are stolen. Coin risk – if the underlying coins are disrupted by bad actors they could lose a large portion of their value. For example, if someone were launching a 51% attack on Ethereum, they could issue large amounts of new Ethereum thus devaluing it. We see this risk as relatively low as we only provide access to the largest most liquid cryptocurrencies on our platform.",
    },
    {
      title: "What is the minimum deposit and withdrawal?",
      desc: "<p>The minimum deposit amount is $100. There isn’t a withdrawal minimum as long as you can cover the fee.</p><p>Do I still own my coins when I send them to Growminer?</p><p>Growminer acts as a cryptocurrency custodian. By doing so, we take full control of customer assets when they are deposited. These deposits are protected by our wallet insurance provided by Fireblocks.</p>"
    },
    {
      title: "How long does it take to deposit or remove funds?",
      desc: "For cryptocurrency we will usually notify you of the receipt of funds within 20 minutes of you making a transfer. With fiat currency, this will depend on your bank and method of payment. Some transfers can take as little as an hour to be confirmed others, such as those sent internationally, can take up to 5 days to clear depending on where your bank is base",
    },
    {
      title: "Why do I need to provide identification?",
      desc: "We take all regulatory and anti-money laundering requirements extremely seriously and adhere to all requirements set by regulators. We have a responsibility to ‘know our customer’ and ensure we know the source of any funds transferred to an account. Please be patient with our questions and document requests as these processes only strengthen the credibility and security of the cryptocurrency ecosystem.",
    },
    {
      title: "Who are Growminer?",
      desc: "<p>Growminer was created by an experienced team of investment, payment and technology experts. Growminer was created out of a frustration with the difficulty that many people face to generate returns from their cryptocurrency. The crypto market was initially created to make financial services available to everyone. However, it has quickly become complex, full of jargon, and impenetrable to every investor. Growminer is here to change that. We have created an easy-to-use platform making it simple to convert fiat into crypto and begin earning high returns on your chosen assets immediately.</p><p>Which cryptocurrencies do you cover?</p><p>You can deposit or invest in Bitcoin (BTC) and Ethereum (ETH) or multiple coins (BNB,USDT,FIL,UNI ). We will add more in response to demand but will always have a strict requirement that the coin have a consistent track record and proven utility.</p>",
    },
    {
      title: "Do you accept corporate customer's?",
      desc: "Yes, we do. To onboard you we will need to identify the business, directors and all beneficial owners. Please email support@growminer.com to learn more.",
    },
  ]

  const reminderContent = {
    title: "Ready to Start?",
    text: "Swap fiat for crypto in seconds and immediately get up to 0.5% in rewards.",
    buttonText: "buy now",
    isDownloadButtons: true,
    banner: bgReady,
  }

  return (
    <div className="markets">
      <Header type="light" />
      <div className="markets-container">
        <BannerList content={bannerContent}>
          <button type="button" className="markets-button">
            Buy Bitcoin
          </button>
        </BannerList>
        <div className="section table">
          <div className="section-wrapper table-wrapper">
            <h2>Bitcoin (BTC) Price Performance</h2>
            <p>
              The current Bitcoin price is $20,364 as in the last 24 hours its value has risen by 2.29%. Bitcoin is down
              70.47% from its all-time high of $69,045. Bitcoin ranks number 1 in terms of market capitalization,
              representing 40.20% of the entire crypto market. Bitcoin is a decentralized currency, which means you can
              buy, sell, and exchange it without having to rely on traditional monetary systems.
            </p>
            <img className="table__image" alt="table" src={table} />
          </div>
        </div>
        <div className="section table-items">
          <div className="section-wrapper table-items-wrapper">
            <h2>Market Status</h2>
            <p>Growminer:Where Staking Meets Trading Volume in a Surprising Twist</p>
            <div className="table-items__container">
              {tableContent.map((el, index) => (
                <div key={index} className="table-items__item">
                  <p>{el.title}</p>
                  <p>{el.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="markets-features-1">
          <Features
            title="Why GrowMiner Is the Right Place to Buy Bitcoin"
            text="GrowMiner, Your fastest and safest route to bitcoin acquisition"
            content={featuresContent1}
          />
        </div>
        <HowTo />
        <div className="corporate markets-features-2">
          <Features title="What Can You Do with Your Bitcoin?" content={featuresContent2} />
        </div>
        <CollapseWrapper items={faqsContent} title="Frequently Asked Questions" />
        <Reminder content={reminderContent} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
