import { useMedia } from "use-media"
import { ReactNode, useState } from "react"
import cn from "classnames"

import { headerLinks, mixins, routes, useScrollPosition } from "utils"

import { ReactComponent as Burger } from "assets/icons/burger.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import logoSrc from "assets/images/logo.webp"
import logoColoredSrc from "assets/images/logo-colored.webp"
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg"

import "./Header.scss"
import { NavLink } from "react-router-dom"

type Props = {
  type?: "light" | "transparent"
}

export const Header = ({ type }: Props): JSX.Element => {
  const scroll = useScrollPosition()
  const [sidebar, setSidebar] = useState(false)
  const isL = useMedia({ maxWidth: mixins.l })

  const toggleSidebar = () => {
    setSidebar(!sidebar)
  }

  return (
    <header
      className={cn(
        "header section",
        {
          offset: scroll > 80 || sidebar,
        },
        {
          light: type === "light",
        },
      )}
    >
      <div className="section-wrapper header-container">
        {isL && (
          <div
            className={cn("header-sidebar", {
              active: sidebar,
            })}
          >
            <div className="header-links-mobile">
              {headerLinks.map((item, index) => (
                <a className="header-right-link" key={index} href={item.link}>
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        )}
        {isL && (
          <button className="header-mobile-burger" type="button" onClick={toggleSidebar}>
            {sidebar ? (
              <Close className="header-mobile-close" fill={"#232F4B"} />
            ) : (
              <Burger fill={scroll > 80 || sidebar || type === "light" ? "#232F4B" : "#FFFFFF"} />
            )}
          </button>
        )}
        <a className="header-logo-wrapper" href={routes.index}>
          <img
            src={scroll > 80 || sidebar || type === "light" ? logoColoredSrc : logoSrc}
            alt="logo"
            className="header-logo"
          />
        </a>
        <div className="header__block_right">
          {!isL && (
            <div className="d-flex header-links">
              {headerLinks.map((item, index) => (
                <NavLink
                  className={({ isActive }) => (isActive ? "header-links__item _active" : "header-links__item")}
                  key={index}
                  to={item.link}
                >
                  {item.title}
                  <Arrow className="header-links__item_arrow" />
                </NavLink>
              ))}
            </div>
          )}
          <div className="header__button">
            <a href="https://pc.growminer.app/" target='_blank' rel='noopener noreferrer'>Sign Up</a>
          </div>
        </div>
      </div>
    </header>
  )
}
