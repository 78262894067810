import banner from "../../assets/images/vulnerability-banner.webp"

import img1 from "../../assets/images/vulnerability/1.svg"
import img2 from "../../assets/images/vulnerability/2.svg"
import img3 from "../../assets/images/vulnerability/3.svg"
import img4 from "../../assets/images/cookies/4.svg"
import img5 from "../../assets/images/vulnerability/4.svg"

import { Banner, Footer, Header, TextImage } from "../../components"

import "./Vulnerability.scss"

export const Vulnerability = (): JSX.Element => {
  const bannerContent = {
    title: "Security Vulnerability Disclosure",
    text: "GrowMiner's security team is excited to work with the community to make sure GrowMiner remains the most secure platform in the crypto space. If you have discovered security vulnerabilities anywhere in our services, we'll greatly appreciate your cooperation in disclosing them to us in a responsible manner, following the guidelines set out below.",
    img: banner,
  }

  const text1Content = {
    title: "Areas of Interest",
    text: "<ul><li>ur primary focus is on vulnerabilities that:</li><li>Allow attackers to access customers’ funds.</li><li>Allow attackers to make customers’ funds unavailable.</li><li>High severity attacks on the server (e.g., remote code execution, SQL injection, etc.)</li></ul>",
    img: img1,
  }

  const text2Content = {
    title: "Out of Scope Vulnerabilities",
    text: "<ul><li>When reporting vulnerabilities, please consider the attack exploitability and security impact of the bug. The following issues are considered out of scope:</li><li>Previously known vulnerable libraries without a working proof of concept.</li><li>Missing best practices in SSL/TLS configuration.</li><li>Any activity that could lead to the disruption of our service (DDOS or SPAM).</li><li>Using automated tools to find vulnerabilities.</li><li>Social engineering.</li></ul>",
    img: img2,
  }

  const text3Content = {
    title: "Guidelines",
    text: "<ul><li>Please provide detailed reports with reproducible steps.</li><li>Submit one vulnerability per report unless you need to chain vulnerabilities to provide impact.</li><li>Try to avoid privacy violations, destruction of data, and interruption of GrowMiner's services. Only interact with accounts you own or with the explicit permission of the account holder.</li></ul>",
    img: img3,
  }

  const text4Content = {
    title: "Disclosures",
    text: "<ul><li>Please email all reports to security@growminer.io. Include any steps required to reproduce or exploit the vulnerability. Please allow enough time for the vulnerability to be addressed before discussing any findings publicly. Once we receive your report, GrowMiner's security team will contact you with a timetable for implementing a fix.</li></ul>",
    img: img4,
  }

  const text5Content = {
    title: "Safe Harbor",
    text: "<ul><li>All activities performed following these guidelines will be considered authorized conduct, and won't be followed by legal action against you. If a third party initiates legal action against you in connection with activities conducted under these guidelines, we will take steps to make it known that your actions were conducted in compliance with GrowMiner's policies.</li></ul>",
    img: img5,
  }

  return (
    <div className="vulnerability">
      <Header type="light" />
      <div className="vulnerability-container">
        <Banner content={bannerContent} />
        <TextImage content={text1Content} />
        <TextImage content={text2Content} />
        <TextImage content={text3Content} />
        <TextImage content={text4Content} />
        <TextImage content={text5Content} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
