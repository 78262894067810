import banner from "../../assets/images/cookies-banner.webp"

import img1 from "../../assets/images/cookies/1.svg"
import img2 from "../../assets/images/cookies/2.svg"
import img3 from "../../assets/images/cookies/3.svg"
import img4 from "../../assets/images/cookies/4.svg"

import "./Cookies.scss"
import { Banner, Footer, Header, TextImage } from "../../components"

export const Cookies = (): JSX.Element => {
  const bannerContent = {
    title: "Cookies Policy",
    text: "GrowMiner’s media center is the place to get the full scoop on what the GrowMiner team got up to in the media.",
    img: banner,
    buttonText: "Read More",
  }

  const text1Content = {
    title: "I. Introduction",
    text: "<ul><li>This Cookies Policy (the “Policy”) governs the relations between you (“Client” or “you”) and any holding company, subsidiary or entity belonging to the GrowMiner group of companies (“GrowMiner”, “we” or “us”), while you and GrowMiner are hereinafter separately referred to as “Party” and jointly - as “Parties”, in regard to your use of the GrowMiner Services and the use and application of cookies and similar technologies while using the GrowMiner Platform.</li><li>You shall take your time and carefully read this Policy before using the GrowMiner Services, so you are fully aware of your rights and obligations. In the spirit of transparency, this Policy provides detailed information about how and when we use cookies on the GrowMiner Platform.</li></ul>",
    img: img1,
  }

  const text2Content = {
    title: "2. Do We Use Cookies?",
    text: "<ul><li>GrowMiner and its partners, affiliates, analytics and service providers use cookies and other similar technologies to ensure that everyone who uses the GrowMiner Platform has the best possible user experience. When you visit the GrowMiner Platform for the first time, a cookie banner will pop up and will give you an explanation about the cookies we use.</li><li>All” button, you consent to us using all the categories of cookies, as described in this Policy. You can disable the use of cookies via your browser or via the given functionality at the Manage Cookies Button when you click on it, but please note that in the former case the GrowMiner Platform may no longer work properly.</li></ul>",
    img: img2,
  }

  const text3Content = {
    title: "3. What Is a Cookie?",
    text: "<ul><li>A cookie is a small text file containing information generated by a website's server and placed on your personal computer or smart device. Cookies enable the website and your browser to interact in such a way that the website is able to present you with the most appropriate options and to customize services to your interests, based on your previous use of the website.</li><li>Some of the cookies will only be used if you use certain features of our services or select certain preferences, and some cookies will always be used. You can find out more about each cookie we use by viewing our current Cookies List below and/or manage your preferences via the Manage Cookies button. We update this list regularly, so there may be additional cookies that are not yet listed.</li></ul>",
    img: img3,
  }

  const text4Content = {
    title: "4. Cookies List",
    text: '<ul class="_no-margin"><li>Strictly Necessary Cookies</li><li>Functional Cookies</li><li>Performance Cookies</li><li>Targeting Cookies</li><li>Social Media Cookies</li><li>Unknown Cookies</li><li>Social Media Cookies</li></ul>',
    img: img4,
  }

  return (
    <div className="cookies">
      <Header type="light" />
      <div className="cookies-container">
        <Banner content={bannerContent} />
        <TextImage content={text1Content} />
        <TextImage content={text2Content} />
        <TextImage content={text3Content} />
        <TextImage content={text4Content} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
