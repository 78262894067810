import {
  Banner,
  Benefits,
  Footer,
  Founder,
  Header,
  LogosSlider,
  Mission,
  Partners,
  TextImage,
  TextSection,
} from "components"

import "./About.scss"
import bg from "../../assets/images/about-text-section.webp"
import logo1 from "../../assets/icons/about/logos1/logo1.svg"
import logo2 from "../../assets/icons/about/logos1/logo2.svg"
import logo3 from "../../assets/icons/about/logos1/logo3.svg"
import logo4 from "../../assets/icons/about/logos1/logo4.svg"
import logo5 from "../../assets/icons/about/logos1/logo5.svg"
import logo6 from "../../assets/icons/about/logos1/logo6.svg"
import logo7 from "../../assets/icons/about/logos1/logo7.svg"
import logo8 from "../../assets/icons/about/logos1/logo8.png"
import logo9 from "../../assets/icons/about/logos1/logo9.svg"
import logo10 from "../../assets/icons/about/logos1/logo10.svg"
import logo11 from "../../assets/icons/about/logos1/logo11.svg"

import logo21 from "../../assets/icons/about/logos2/logo1.png"
import logo22 from "../../assets/icons/about/logos2/logo2.png"
import logo23 from "../../assets/icons/about/logos2/logo3.svg"
import logo24 from "../../assets/icons/about/logos2/logo4.png"
import logo25 from "../../assets/icons/about/logos2/logo5.svg"
import logo26 from "../../assets/icons/about/logos2/logo6.svg"
import logo27 from "../../assets/icons/about/logos2/logo7.svg"
import logo28 from "../../assets/icons/about/logos2/logo8.png"
import logo29 from "../../assets/icons/about/logos2/logo9.png"

import logo31 from "../../assets/icons/about/logos3/logo1.svg"
import logo32 from "../../assets/icons/about/logos3/logo2.svg"
import logo33 from "../../assets/icons/about/logos3/logo3.svg"
import logo34 from "../../assets/icons/about/logos3/logo4.svg"
import logo35 from "../../assets/icons/about/logos3/logo5.svg"
import logo36 from "../../assets/icons/about/logos3/logo6.svg"

import banner from "assets/images/about-bg.png"

export const About = (): JSX.Element => {
  const textSectionContent = {
    title: "Careers",
    text: "We are looking for exceptionally talented people to join our team Imagine yourself contributing to the development of one of the most dynamic blockchain-based FinTech companies in the world, redefining how financial services are conducted. This innovative company is GrowMiner.",
    buttonText: "View all openings",
    img: bg,
  }

  const partnersSectionContent1 = {
    title: "Strategic Partners",
    logos: [
      {
        icon: logo1,
      },
      {
        icon: logo2,
      },
      {
        icon: logo3,
      },
      {
        icon: logo4,
      },
      {
        icon: logo5,
      },
      {
        icon: logo6,
      },
      {
        icon: logo7,
      },
      {
        icon: logo8,
      },
      {
        icon: logo9,
      },
      {
        icon: logo10,
      },
      {
        icon: logo11,
      },
    ],
  }

  const partnersSectionContent2 = {
    title: "Memberships",
    logos: [
      {
        icon: logo21,
      },
      {
        icon: logo22,
      },
      {
        icon: logo23,
      },
      {
        icon: logo24,
      },
      {
        icon: logo25,
      },
      {
        icon: logo26,
      },
      {
        icon: logo27,
      },
      {
        icon: logo28,
      },
      {
        icon: logo29,
      },
    ],
  }

  const partnersSectionContent3 = {
    logos: [
      {
        icon: logo31,
      },
      {
        icon: logo32,
      },
      {
        icon: logo33,
      },
      {
        icon: logo34,
      },
      {
        icon: logo35,
      },
      {
        icon: logo36,
      },
      {
        icon: logo36,
      },
      {
        icon: logo36,
      },
      {
        icon: logo36,
      },
    ],
  }

  const bannerContent = {
    title: "About Us",
    subtitle: "Disrupting the financial system,one bit at a time.",
    img: banner,
  }

  return (
    <div className="about">
      <Header type="light" />
      <div className="about-container">
        <Banner content={bannerContent} />
        <TextSection />
        <Benefits />
        <Founder />
        <Mission />
        <div className="about-partners_1">
          <Partners content={partnersSectionContent1} spacer={true} />
        </div>
        <TextImage content={textSectionContent} />
        <div className="about-partners_2">
          <Partners content={partnersSectionContent2} spacer={false} />
        </div>
        <LogosSlider content={partnersSectionContent3} />
        <Footer type="dark" />
      </div>
    </div>
  )
}
