import { useRef, useState } from "react"
import cn from "classnames"

import { ReactComponent as Arrow } from "assets/icons/arrow-up.svg"
import "./Collapse.scss"

type Props = {
  desc: string
  title: string
  index?: number
}

export const Collapse = ({ desc, title, index }: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      ref={wrapperRef}
      className={cn("collapse-wrapper", {
        open: isOpen,
      })}
    >
      <button ref={titleRef} type="button" onClick={toggleOpen} className="collapse-title">
        <div className="collapse-title-wrapper">
          <div className="collapse-title-index">{index}</div>
          <span className="collapse-title-text">{title}</span>
        </div>
        <div style={{ transform: isOpen ? "rotate(180deg)" : "" }} className="collapse-arrow">
          <Arrow />
        </div>
      </button>
      <div className="collapse-content">
        <p
          dangerouslySetInnerHTML={{
            __html: desc ?? "",
          }}
          className="collapse-content-desc"
        />
      </div>
    </div>
  )
}
