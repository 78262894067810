import img1 from "assets/icons/about/mission/1.svg"
import img2 from "assets/icons/about/mission/2.svg"
import img3 from "assets/icons/about/mission/3.svg"

import arrow1 from "assets/icons/about/mission/arrow1.svg"
import arrow2 from "assets/icons/about/mission/arrow2.svg"

import "./Mission.scss"

export const Mission = (): JSX.Element => {
  const items = [
    {
      icon: img1,
      title: "Our Mission",
      text: "Eliminate greenhouse gas emissions by creating the world's most recognized and trusted climate label, paired with accessible.",
    },
    {
      icon: img2,
      title: "Our Vision",
      text: "All individuals can use their purchasing power against climate change, and all brands have the tools to be climate neutral.",
    },
    {
      icon: img3,
      title: "Our Value",
      text: "All individuals can use their purchasing power against climate change, and all brands have the tools to be climate neutral.",
    },
  ]

  return (
    <div className="section mission">
      <div className="section-wrapper mission-wrapper">
        <h2>Mission, Vision & Values</h2>
        <div className="mission__items">
          {items.map((item, index) => (
            <div key={index} className="mission__item">
              <div className="mission__item-img-wrapper">
                <img className="mission__item-img" alt="mission" src={item.icon} />
              </div>
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          ))}
          <div className="mission__arrow _1">
            <img alt="arrow" src={arrow1} />
          </div>
          <div className="mission__arrow _2">
            <img alt="arrow" src={arrow2} />
          </div>
        </div>
      </div>
    </div>
  )
}
