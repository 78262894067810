import "./Data.scss"
import { useState } from "react"
import cn from "classnames"
import { ReactComponent as Outline } from "assets/icons/future-outline.svg"

export const Data = (): JSX.Element => {
  const [tab, setTab] = useState<"information" | "progress">("information")

  return (
    <div className="data">
      {/*<div className="data-content">*/}
      {/*  <h2 className="data-title">*/}
      {/*    Mining Pool Operation Data*/}
      {/*    <Outline />*/}
      {/*  </h2>*/}
      {/*  <div className="data-tabs">*/}
      {/*    <button*/}
      {/*      onClick={() => setTab("progress")}*/}
      {/*      className={cn("data-tabs-item", {*/}
      {/*        active: tab === "progress",*/}
      {/*      })}*/}
      {/*    >*/}
      {/*      Filling Progress*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      onClick={() => setTab("information")}*/}
      {/*      className={cn("data-tabs-item", {*/}
      {/*        active: tab === "information",*/}
      {/*      })}*/}
      {/*    >*/}
      {/*      Data Information*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*  {tab === "progress" && (*/}
      {/*    <div className="data-content-first">*/}
      {/*      <div className="data-content-items">*/}
      {/*        <div className="data-content-first-item">*/}
      {/*          <h4 className="data-content-first-item-title">6,820PiB</h4>*/}
      {/*          <p className="data-content-first-item-desc">Profit Ratio Per Day</p>*/}
      {/*        </div>*/}
      {/*        <div className="data-content-first-item">*/}
      {/*          <h4 className="data-content-first-item-title">6,820PiB</h4>*/}
      {/*          <p className="data-content-first-item-desc">Profit Ratio Per Day</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  {tab === "information" && (*/}
      {/*    <div className="data-content-second">*/}
      {/*      <div className="data-content-items">*/}
      {/*        <div className="data-content-second-item">*/}
      {/*          <h4 className="data-content-second-item-title">1,179,976PiB</h4>*/}
      {/*          <p className="data-content-second-item-desc">Profit per day</p>*/}
      {/*        </div>*/}
      {/*        <div className="data-content-second-item">*/}
      {/*          <h4 className="data-content-second-item-title">8,220.17PiB</h4>*/}
      {/*          <p className="data-content-second-item-desc">Profit per week</p>*/}
      {/*        </div>*/}
      {/*        <div className="data-content-second-item">*/}
      {/*          <h4 className="data-content-second-item-title">22.8819PiB</h4>*/}
      {/*          <p className="data-content-second-item-desc">Profit per month</p>*/}
      {/*        </div>*/}
      {/*        <div className="data-content-second-item">*/}
      {/*          <h4 className="data-content-second-item-title">0.0435PiB</h4>*/}
      {/*          <p className="data-content-second-item-desc">Profit per year</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className="node-info">
        <h2 className="data-second-title">
          Node Information
          <Outline />
        </h2>
        <h3 className="data-third-title">ETH Node</h3>
        <div className="table-wrapper">
          <table>
            <tr className="data-content-third-header">
              <th>Node Id</th>
              <th>Host</th>
              <th>Port</th>
              <th>Total Difficulty</th>
              <th>Client Type</th>
              <th>Client Version</th>
              <th>OS</th>
              <th>Country</th>
            </tr>
            <tr>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://etherscan.io/nodetracker/node/292fb7269066aa07620c5ee4e34efb460fe82874dea4f260eb3e2ded5f51827525585ec5d65187e8c3721c17cc5fdec28cea0bda8cbc9728961284e1570cf44e"
                >
                  292f...f44e
                </a>
              </td>
              <td>209.30.144.92</td>
              <td>30303</td>
              <td>
                <div className="nowrap">47,522,534,134,527,289,553,880</div>
              </td>
              <td>Geth</td>
              <td>go1.18</td>
              <td>darwin-arm64</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://etherscan.io/nodetracker/node/fa006c9f8a2904b55835dc67650e9dbfb139d1813865e28053d69ca67a75f4f81e945e6f8a9e56769521b92d3e64699f0553ca20dafd0e8ccd92c5bd8a5b8d51"
                >
                  fa00...8d51
                </a>
              </td>
              <td>54.198.223.59</td>
              <td>30303</td>
              <td>
                <div className="nowrap">47,596,621,056,583,403,157,024</div>
              </td>
              <td>Geth</td>
              <td>go1.18</td>
              <td>linux-amd64</td>
              <td>United States</td>
            </tr>
          </table>
        </div>
        <h3 className="data-third-title">Filecoin Node</h3>
        <div className="table-wrapper">
          <table>
            <tr className="data-content-third-header">
              <th>Address</th>
              <th>Number of messages</th>
              <th>Type</th>
              <th>Created</th>
              <th>Node ID</th>
              <th>Owner</th>
              <th>Worker</th>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="https://filfox.info/en/address/f01946924">
                  f01946924
                </a>
              </td>
              <td>501300</td>
              <td>Storage Miner</td>
              <td>2022-11-01 04:22:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/peer/12D3KooWRvyEC1JVigB9ZkcfVZR2BkLx3FudKbwvcqJ1VpK8EgEC"
                >
                  12D3...EgEC
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f1ficd6whteyg2x2wmt2t64bv5picglj3525kzloi"
                >
                  f1fi...zloi
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f3widzjp5br4gpg4q4e4rwjsyut55i4lvsguwwsnmdz6ftho2m62ms655jbnd3ztzklo7h6ihyen5vw627qilq"
                >
                  f3wi...qilq
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="https://filfox.info/en/address/f01930668">
                  f01930668
                </a>
              </td>
              <td>1045777</td>
              <td>Storage Miner</td>
              <td>2022-10-02 18:38:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/peer/12D3KooWEM3xizzvgcXEG3hvxvpdmeRG4a4yNkRWJqYGFbK6herC"
                >
                  12D3...herC
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f1xq7a43bxzvfnscxmokw2eujpkl7vfkramxrpkhy"
                >
                  f1xq...pkhy
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f3r5hofm3z6cg5vmcryqgq6h3ec3n75xxrondwuytyxdszsgc7icxyz5u5lh27hsuw3v3nzdyl5pb7jefcwe5a"
                >
                  f3r5...we5a
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="https://filfox.info/en/address/f0843713">
                  f0843713
                </a>
              </td>
              <td>426126</td>
              <td>Storage Miner</td>
              <td>2021-05-17 19:16:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/peer/12D3KooWMrwcFCdRqmRwyqJCCUGwCMiQkUB3nbHDrL72auUHT7j6"
                >
                  12D3...T7j6
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f3vggroyg2hncga4bgw4gd6w444pia4lafowj2oizu763jsuvjya6fndpiworgmc7zni3jn7unk2sgrwp246sa"
                >
                  f3vg...46sa
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/address/f3vggroyg2hncga4bgw4gd6w444pia4lafowj2oizu763jsuvjya6fndpiworgmc7zni3jn7unk2sgrwp246sa"
                >
                  f3vg...46sa
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="https://filfox.info/zh/address/f0844271">
                  f0844271
                </a>
              </td>
              <td>186982</td>
              <td>Storage provider account</td>
              <td>2021-05-18 05:08:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/en/peer/12D3KooWRx2bAvjQKzS3xzMc1XCWG7SdrMPT91sanvTbK94JgoY3"
                >
                  12D3...goY3
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/zh/address/f410fzdso6eki2epyyvl7m57ohrzzahgxs27wbwomb2i"
                >
                  f410...mb2i
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://filfox.info/zh/address/f3uovi5rfc3tdko4rofs3kgmdruxch5ejpuwyhht5ip46tbe4nv2o3wcdlgzblwmseraa3siwys5kyd777e2va"
                >
                  f3uo...e2va
                </a>
              </td>
            </tr>
          </table>
        </div>

        {/* <a className="data-link">
          <span className="data-link-text">View Account</span>
        </a> */}
      </div>
    </div>
  )
}
