import "./CollapseWrapper.scss"
import { Collapse } from "../Collapse"

type Props = {
  items: {
    title: string
    desc: string
  }[]
  title?: string
  text?: string
}

export const CollapseWrapper = ({ items, title, text }: Props): JSX.Element => {
  return (
    <div className="section collapse-wrap">
      <div className="section-wrapper">
        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}
        <div className="collapse-wrap__container">
          {items.map((el, index) => (
            <Collapse key={index} desc={el.desc} title={el.title} index={index + 1} />
          ))}
        </div>
      </div>
    </div>
  )
}
