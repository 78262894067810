import logoSrc from "assets/images/logo.webp"
import appStore from "assets/icons/download/app-store.svg"
import playStore from "assets/icons/download/play-store.svg"
import bg from "assets/images/exchange-bg.webp"

import img1 from "assets/images/exchanges/1.png"
import img2 from "assets/images/exchanges/2.png"
import img3 from "assets/images/exchanges/3.png"
import img5 from "assets/images/exchanges/5.png"
import img6 from "assets/images/exchanges/6.png"
import img7 from "assets/images/exchanges/7.png"
import img8 from "assets/images/exchanges/8.png"
import img9 from "assets/images/exchanges/9.png"
import img10 from "assets/images/exchanges/10.png"
import img11 from "assets/images/exchanges/11.png"
import img12 from "assets/images/exchanges/12.png"
import img13 from "assets/images/exchanges/13.png"
import img14 from "assets/images/exchanges/14.png"
import img15 from "assets/images/exchanges/15.png"
import img16 from "assets/images/exchanges/16.png"
import img17 from "assets/images/exchanges/17.png"
import img18 from "assets/images/exchanges/18.png"
import img19 from "assets/images/exchanges/19.png"
import img20 from "assets/images/exchanges/20.png"
import img21 from "assets/images/exchanges/21.png"
import img22 from "assets/images/exchanges/22.png"
import img23 from "assets/images/exchanges/23.png"

import "./Exchanges.scss"
import { Footer, Header } from "../../components"

export const Exchanges = (): JSX.Element => {
  const downloadLinks = [
    {
      icon: appStore,
      href: "/",
    },
    {
      icon: playStore,
      href: "/",
    },
  ]

  const items = [
    {
      img: img1,
      text: "how to withdraw from bitmex",
      link: "/",
    },
    {
      img: img2,
      text: "how to withdraw crypto from paxful",
      link: "/",
    },
    {
      img: img3,
      text: "how to withdraw crypto from OKX",
      link: "/",
    },
    {
      img: img1,
      text: "how to withdraw crypto from robinhood",
      link: "/",
    },
    {
      img: img5,
      text: "how to withdraw from poloiex",
      link: "/",
    },
    {
      img: img6,
      text: "how to withdraw crypto from hitBTC",
      link: "/",
    },
    {
      img: img7,
      text: "how to withdraw crypto from bittrex",
      link: "/",
    },
    {
      img: img8,
      text: "how to withdraw crypto from revolut",
      link: "/",
    },
    {
      img: img9,
      text: "how to withdraw from bitMart",
      link: "/",
    },
    {
      img: img10,
      text: "how to withdraw money from CEX.IO",
      link: "/",
    },
    {
      img: img11,
      text: "how to withdraw money from bitfinex",
      link: "/",
    },
    {
      img: img12,
      text: "how to withdraw crypto from etoro",
      link: "/",
    },
    {
      img: img13,
      text: "how to send bitcoin on cash app to another wellet",
      link: "/",
    },
    {
      img: img14,
      text: "how to withdraw money from uphold",
      link: "/",
    },
    {
      img: img15,
      text: "how to withdraw money from bitstamp",
      link: "/",
    },
    {
      img: img16,
      text: "how to withdraw crypto from bybit",
      link: "/",
    },
    {
      img: img17,
      text: "how to withdraw money from huobi",
      link: "/",
    },
    {
      img: img18,
      text: "how to withdraw money from kucoin",
      link: "/",
    },
    {
      img: img19,
      text: "how to withdraw money from gemini",
      link: "/",
    },
    {
      img: img20,
      text: "how to withdraw crypto from coinbase",
      link: "/",
    },
    {
      img: img21,
      text: "how to withdraw money from crypto.com",
      link: "/",
    },
    {
      img: img22,
      text: "how to withdraw money from karken",
      link: "/",
    },
    {
      img: img23,
      text: "how to withdraw money from binance",
      link: "/",
    },
  ]

  return (
    <div className="exchanges">
      <Header type="light" />
      <div className="security-container">
        <div className="section exchanges-list">
          <div className="section-wrapper exchanges-list-wrapper">
            <h1>How to Withdraw Crypto from Exchanges</h1>
            <div className="exchanges-list__container">
              {items.map((el, index) => (
                <a key={index} href={el.link}>
                  <div className="exchanges-list__item">
                    <img src={el.img} alt="logo" />
                    <p>{el.text}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div style={{ background: `url("${bg}") center/cover no-repeat` }} className="section exchanges-download">
          <div className="section-wrapper exchanges-download-wrapper">
            <h2>Ready, GrowMiner!</h2>
            <img alt="logo" src={logoSrc} />
            <div className="exchanges-download-links">
              {downloadLinks.map((item, index) => (
                <a key={index} href={item.href}>
                  <img className="exchanges-download-links__item" src={item.icon} alt="download" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer type="dark" />
    </div>
  )
}
