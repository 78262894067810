import arrow from "../../assets/icons/read-more-arrow.svg"

import "./Features.scss"

type Props = {
  title: string
  text?: string
  content: {
    title?: string
    text?: string
    img: string
  }[]
  button?: boolean
  buttonText?: string
}

export const Features = ({ title, text, content, button, buttonText }: Props): JSX.Element => {
  return (
    <div className="section features">
      <div className="section-wrapper features-wrapper">
        <h2>{title}</h2>
        {text && <p>{text}</p>}
        <div className="features-container">
          {content.map((el, index) => (
            <div key={index} className="features__item">
              <div className="features__item-img-wrapper">
                <img src={el.img} alt="feature" />
              </div>
              <h3>{el.title}</h3>
              <p>{el.text}</p>
              {button && (
                <button>
                  {buttonText || "Read More"} <img src={arrow} alt="arrow" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
