import banner from "../../assets/images/media-banner.webp"
import textImg from "../../assets/images/media-text.svg"
import reminderImg from "../../assets/images/media-reminder.png"
import arrow from "../../assets/icons/arrow-right-blue.svg"

import img1 from "../../assets/images/media-center/1.png"
import img2 from "../../assets/images/media-center/2.png"
import img3 from "../../assets/images/media-center/3.png"
import img4 from "../../assets/images/media-center/4.png"
import img6 from "../../assets/images/media-center/6.png"

import { Banner, Footer, Header, Reminder, TextImage } from "../../components"
import cn from "classnames"

import "./MediaCenter.scss"

export const blogCard = (el, index) => (
  <div key={index} className="news__item">
    <div className="news__item-img-wrapper">
      {el?.subtitle && <div className="news__item-subtitle">{el.subtitle}</div>}
      <img className="news__item-img" alt="news" src={el.img} />
    </div>
    <div>
      <h3>{el.title}</h3>
      <p className="news__item-text">{el.text}</p>
      <p className="news__item-date">{el.date}</p>
      <button type="button" className="news__item-button">
        Learn more
        <img alt="arrow" src={arrow} />
      </button>
    </div>
  </div>
)

export const MediaCenter = (): JSX.Element => {
  const bannerContent = {
    title: "Media Center",
    text: '<p>GrowMiner’s media center is the place to get the full scoop on what the GrowMiner team got up to in the media.</p><p>For inquiries, please reach out to <a href="malito:pr@growminer.io">pr@growminer.io</a>.</p>',
    buttonText: "",
    img: banner,
  }

  const textContent = {
    title: "Download GrowMinerBrand Assets",
    text: "Get our full brand assets kit, including logos, symbols, product screenshots, and a usage guide to help determine when and how to utilize our brand.",
    buttonText: "Download Assets",
    img: textImg,
  }

  const reminderContent = {
    title: "Let Your Crypto Work For You",
    text: "The only cryptocurrency account that lets you borrow instantly in 45+ fiat currencies and earns you daily interest on idle assets",
    buttonText: "Get Started ",
    banner: reminderImg,
  }

  const buttons = ["All", "In The News", "Press Releases"]

  const news = [
    {
      title: "Finance Monthly",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img1,
    },
    {
      title: "Coindesk",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img2,
    },
    {
      title: "GrowMiner",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img3,
    },
    {
      title: "Finance Monthly",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img4,
    },
    {
      title: "Finance Monthly",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img2,
    },
    {
      title: "Finance Monthly",
      text: "Is 2023 The Year That Cryptocurrencies Can Rebuild Trust And Transparency?",
      date: "Feb 28, 2020",
      img: img6,
    },
  ]

  return (
    <div className="media-center">
      <Header type="light" />
      <div className="media-center-container">
        <Banner content={bannerContent} />
        <div className="section news">
          <div className="section-wrapper news-wrapper">
            <div className="news-buttons__container">
              {buttons.map((el, index) => (
                <div
                  key={index}
                  className={cn("news-buttons__item switch-buttons__item", {
                    _active: index === 0,
                  })}
                >
                  {el}
                </div>
              ))}
            </div>
            <div className="news__container">
              {news.map((el, index) => (
                <div key={index}>{blogCard(el, index)}</div>
              ))}
            </div>
          </div>
        </div>
        <TextImage content={textContent} />
        <Reminder content={reminderContent} removeArrow={true} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
