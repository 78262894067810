import { hrefs, footerLinks } from "utils"

import { ReactComponent as Facebook } from "assets/icons/socials/facebook.svg"
import { ReactComponent as Instagram } from "assets/icons/socials/instagram.svg"
import { ReactComponent as Twitter } from "assets/icons/socials/twitter.svg"
import { ReactComponent as Telegram } from "assets/icons/socials/telegram.svg"

import appStore from "assets/icons/download/app-store.svg"
import playStore from "assets/icons/download/play-store.svg"

import sendIcon from "../../assets/icons/send.svg"
import sendWhiteIcon from "../../assets/icons/send-white.svg"

import "./Footer.scss"
import cn from "classnames"

export const Footer = ({ type }: { type?: string }): JSX.Element => {
  const socials = [
    {
      socType: "facebook",
      href: hrefs.facebook,
    },
    {
      socType: "instagram",
      href: hrefs.instagram,
    },
    {
      socType: "twitter",
      href: hrefs.twitter,
    },
    {
      socType: "telegram",
      href: hrefs.telegram,
    },
  ]

  const downloadLinks = [
    {
      icon: appStore,
      href: hrefs.apple,
    },
    {
      icon: playStore,
      href: hrefs.googlePlay,
    },
  ]

  return (
    <div
      className={cn("footer", {
        _light: type === "light",
        _dark: type === "dark",
      })}
    >
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-top__content">
            <h3>GrowMiner</h3>
            <p>
              Nurture your wealth, stake with GrowMiner&apos;s comprehensive platform, and enjoy the benefits of
              long-term financial stability and growth
            </p>
            <div className="footer-socials">
              {socials.map((item, index) => (
                <a
                  className="footer-socials__item"
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.socType === "facebook" && <Facebook fill={type === "light" ? "#000F29" : "#FFFFFF"} />}
                  {item.socType === "instagram" && <Instagram fill={type === "light" ? "#000F29" : "#FFFFFF"} />}
                  {item.socType === "twitter" && <Twitter fill={type === "light" ? "#000F29" : "#FFFFFF"} />}
                  {item.socType === "telegram" && <Telegram fill={type === "light" ? "#000F29" : "#FFFFFF"} />}
                  {/*{item.socType === "telegram" && <Telegram />}*/}
                </a>
              ))}
            </div>
            <h4 className="footer-links__title">Get appex on your device:</h4>
            <div className="footer-links">
              {downloadLinks.map((item, index) => (
                <a target='_blank' rel='noopener noreferrer' className="footer-links__item" key={index} href={item.href}>
                  <img src={item.icon} alt="download" />
                </a>
              ))}
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-menu">
              {footerLinks.map((item, index) => (
                <div className="footer-menu__item" key={index}>
                  <div className="footer-menu__title">{item.title}</div>
                  {item.links.map((item, index) => (
                    <a className="footer-links__link" key={index} href={item.link}>
                      {item.title}
                    </a>
                  ))}
                </div>
              ))}
            </div>
            <div className="footer-form">
              <div className="footer-menu__title">Subscribe</div>
              <input
                style={{ backgroundImage: `url("${type === "light" ? sendIcon : sendWhiteIcon}")` }}
                placeholder="Email Address"
                type="email"
              />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom__border" />© 2023 GrowMiner. All rights reserved.
        </div>
      </div>
    </div>
  )
}
