export const hrefs = {
  facebook: "https://www.facebook.com/growminer",
  twitter: "https://twitter.com/Growminer_",
  telegram: "https://t.me/Grow_Miner",
  instagram: "https://instagram.com/growminer_?igshid=MzRlODBiNWFlZA==",
  youtube: "https://www.youtube.com/@Triminer_official",
  website: "https://triminer.com/",
  linkedIn: "https://triminer.com/",
  googlePlay: "https://play.google.com/store/apps/details?id=com.wqeqs.growminer",
  apple: "https://apps.apple.com/us/app/grow-pro/id6449863741"
}