import img1Src from "assets/icons/benefits/1.svg"
import img2Src from "assets/icons/benefits/2.svg"
import img3Src from "assets/icons/benefits/3.svg"
import img4Src from "assets/icons/benefits/4.svg"

import "./Benefits.scss"

export const Benefits = (): JSX.Element => {
  const benefits = [
    {
      image: img1Src,
      title: "$130B+",
      text: "Processed in5+ years",
    },
    {
      image: img2Src,
      title: "5M+",
      text: "GrowMiner users worldwide",
    },
    {
      image: img3Src,
      title: "60+",
      text: "Supported cryptocurrencies",
    },
    {
      image: img4Src,
      title: "200+",
      text: "Available jurisdictions",
    },
  ]

  return (
    <div className="section benefits">
      <div className="section-wrapper benefits-wrapper">
        {benefits.map((benefit, index) => (
          <div className="benefits__item" key={index}>
            <img src={benefit.image} alt="client" />
            <h3>{benefit.title}</h3>
            <span>{benefit.text}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
