import React, { ReactChild, useEffect, useState } from "react"
import cn from "classnames"
import SwipeableViews from "react-swipeable-views"

import "./Carousel.scss"

type Props = {
  children: ReactChild[]
  className?: string
  onChangeIndex?: (index: number) => void
  autoChange?: boolean
}
export const Carousel = ({ children, className, onChangeIndex, autoChange }: Props): JSX.Element => {
  const [active, setActive] = useState(0)

  const onChangeActive = (newActive: number) => {
    setActive(newActive)
    if (onChangeIndex) {
      onChangeIndex(newActive)
    }
  }

  const autoChangeSlide = () => {
    if (active === children.length - 1) {
      setActive(0)
      if (onChangeIndex) {
        onChangeIndex(0)
      }
    } else {
      setActive(active + 1)
      if (onChangeIndex) {
        onChangeIndex(active + 1)
      }
    }
  }

  useEffect(() => {
    if (autoChange) {
      const id = setInterval(autoChangeSlide, 8000)
      return () => clearInterval(id)
    }
  }, [autoChangeSlide, active, autoChange])

  return (
    <div className="carousel">
      <SwipeableViews
        index={active}
        onChangeIndex={onChangeActive}
        enableMouseEvents
        className={cn("carousel-swipeable", className)}
      >
        {children}
      </SwipeableViews>
      <div className="carousel-dots">
        {children?.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              if (onChangeIndex) {
                onChangeIndex(index)
              }
              setActive(index)
            }}
            className={cn("carousel-dots-item", {
              active: index === active,
            })}
          />
        ))}
      </div>
    </div>
  )
}
