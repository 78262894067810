import img1 from "../../assets/icons/corporate/steps/1.svg"
import img2 from "../../assets/icons/corporate/steps/2.svg"
import img3 from "../../assets/icons/corporate/steps/3.svg"
import arrow from "../../assets/icons/arrow-down_white.svg"

import "./Steps.scss"
import { useMedia } from "use-media"
import { mixins } from "../../utils"

export const Steps = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })

  const items = [
    {
      title: "Create Account",
      text: "Open a standard GrowMiner account if you don’t already have one.",
      icon: img1,
    },
    {
      title: "Apply",
      text: "Apply for a GrowMiner Corporate account by filling out the onboarding form.",
      icon: img2,
    },
    {
      title: "Get Approved",
      text: "Our team will reach out with the next steps, usually within 2 business days.",
      icon: img3,
    },
  ]

  return (
    <div className="section steps">
      <div className="section-wrapper steps-wrapper">
        <h2>Onboard in 3 Easy Steps</h2>
        <p className="steps__text">
          GrowMiner guarantees swift onboarding for companies, regardless of the state or country of incorporation. The
          process is usually completed within 2 business days.
        </p>
        <div className="steps-container">
          <div className="steps__items">
            {items.map((el, index) => {
              const ind = index + 1
              return (
                <div key={index} className="steps__item">
                  {ind % 2 === 0 && !isS && (
                    <div style={{ textAlign: "right" }} className="steps__item-content">
                      <h3>{el.title}</h3>
                      <p>{el.text}</p>
                    </div>
                  )}
                  <div
                    className="steps__item-ind"
                    style={{
                      justifyContent: ind % 2 === 1 ? "flex-end" : "flex-start",
                    }}
                  >
                    <div className="steps__item-number">{ind}.</div>
                    <div className="steps__item-icon">
                      <img alt="step" src={el.icon} />
                    </div>
                  </div>
                  {(ind % 2 === 1 || isS) && (
                    <div className="steps__item-content">
                      <h3>{el.title}</h3>
                      <p>{el.text}</p>
                    </div>
                  )}
                </div>
              )
            })}
            <div className="steps__decor-line" />
            <div className="steps__decor-circle _1" />
            <div className="steps__decor-circle _2" />
            <div className="steps__decor-circle _3" />
          </div>
          <a style={{ backgroundImage: `url("${arrow}")` }} type="button" href="/prime#contact-us" className="button">
            apply now
          </a>
        </div>
      </div>
    </div>
  )
}
