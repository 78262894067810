import { BannerDown, Features, Footer, Header, Steps, TextImage } from "../../components"

import "./Corporate.scss"
import bgBanner from "../../assets/images/corporate-banner.webp"
import bgBenefits from "../../assets/images/rectangle-decor.svg"
import bgReady from "assets/images/exchange-bg.webp"
import bgPlanet from "assets/images/corporate-bg.svg"
import logo from "assets/icons/corporate/armanino.svg"

import img1 from "../../assets/icons/corporate/benefits/1.svg"
import img2 from "../../assets/icons/corporate/benefits/2.svg"
import img3 from "../../assets/icons/corporate/benefits/3.svg"
import img4 from "../../assets/icons/corporate/benefits/4.svg"

import img11 from "../../assets/icons/corporate/benefits-2/1.svg"
import img12 from "../../assets/icons/corporate/benefits-2/2.svg"
import img13 from "../../assets/icons/corporate/benefits-2/3.svg"
import img14 from "../../assets/icons/corporate/benefits-2/4.svg"
import img15 from "../../assets/icons/corporate/benefits-2/5.svg"
import img16 from "../../assets/icons/corporate/benefits-2/6.svg"
import arrow from "../../assets/icons/arrow-down_white.svg"

export const Corporate = (): JSX.Element => {
  const bannerContent = {
    title: `Grow Your Business Through GrowMiner’s Services for Corporations`,
    text: "Build, manage, and grow your company’s digital asset portfolio through institutional-level services and the highest level of security.",
    subTitle: "GrowMiner CORPORATE ACCOUNT",
    img: bgBanner,
  }

  const benefitsContent = [
    {
      title: "Dedicated relationship manager",
      img: img1,
    },
    {
      title: "Superior OTC services",
      img: img2,
    },
    {
      title: "Institutionaltools & custody",
      img: img3,
    },
    {
      title: "Deep liquidity &best execution",
      img: img4,
    },
  ]

  const featuresContent = [
    {
      title: "RelationshipManager",
      text: "As your primary point of contact, your dedicated relationship manager, will provide you with industry-leading insight — expert crypto knowledge is always just a phone call away.",
      img: img11,
    },
    {
      title: "Borrow, Earn &Exchange",
      text: "Get access to GrowMiner’s OTC borrowing, lending, and trading desk. Borrow via an institutional credit line with flexible settlement and generate passive income efficiently and securely.",
      img: img12,
    },
    {
      title: "SuperiorOTC Services",
      text: "Trade large volumes on the OTC desk without realizing material slippage and enjoy tailor-made rates based on market movements rather than predefined terms and conditions.",
      img: img13,
    },
    {
      title: "Institutional Tools &Custody",
      text: "GrowMiner Prime offers advanced spot and derivatives trading, efficient margin provision, and secure settlement. Our custody framework combines industry-leading solutions from Bakkt, Fireblocks, and Ledger Vault.",
      img: img14,
    },
    {
      title: "Deep Liquidity &Best Execution",
      text: "Utilize diversified liquidity and execute large block orders efficiently, reliably, and at competitive trading fees thanks to GrowMiner Prime’s best pricing and aggregated liquidity mechanism.",
      img: img15,
    },
    {
      title: "Priority CustomerSupport",
      text: "All your customer support requests will be prioritized, so you never have to wait in line. Don’t hesitate to reach out if any technical questions arise and get timely, high-touch assistance.",
      img: img16,
    },
  ]

  const numbersContent = [
    {
      title: "$130B+",
      text: "Processed in 5+ years",
    },
    {
      title: "60+",
      text: "Support eddigital assets",
    },
    {
      title: "50+",
      text: "Licences &registrations",
    },
    {
      title: "200+",
      text: "Available jurisdictions",
    },
  ]

  const textImageContent = {
    title: "Secure Custody for Your Company’s Assets",
    text: "Our robust business model, prudent risk management, and strict collateralization requirements ensure maximum protection of your crypto wealth at all times.",
    buttonText: "learn more",
    img: bgPlanet,
  }

  const attestationItems = [
    {
      title: "Independent",
      text: "GrowMiner's auditor is one of the top 25 independent CPA firms in the US.",
    },
    {
      title: "Real-Time",
      text: "Verify that GrowMiner is able to meet client obligations at any given time.",
    },
    {
      title: "Fully-Backed",
      text: "Your holdings on the GrowMiner platform are backed by more than 100%.",
    },
  ]

  return (
    <div className="corporate">
      <Header type="light" />
      <div className="corporate-container">
        <BannerDown content={bannerContent} buttonDown={false} />
        <div style={{ backgroundImage: `url("${bgBenefits}")` }} className="section corporate-benefits">
          <div className="section-wrapper corporate-benefits-wrapper">
            {benefitsContent.map((el, index) => (
              <div key={index} className="corporate-benefits__item">
                <img src={el.img} alt="benefit" />
                <p>{el.title}</p>
              </div>
            ))}
          </div>
        </div>
        <Steps />
        <Features
          title="The Benefits for Your Business"
          text="Enjoy an exclusive layer of perks to bring your digital asset strategy to life."
          content={featuresContent}
          button={false}
        />
        <div className="section corporate-numbers">
          <div className="section-wrapper corporate-numbers-wrapper">
            <div className="corporate-numbers__content">
              <h2>Trust a Leading Regulated Institution</h2>
              <p>
                Holding over 50 licenses, GrowMiner has gone the extra mile in securing compliance with the applicable
                regulatory frameworks. Together with our top-tier legal counsel and engaging proactively with regulatory
                decision-makers, we ensure the sustainability of our products for years to come.
              </p>
              <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
                learn more
              </button>
            </div>
            <div className="corporate-numbers-container">
              {numbersContent.map((el, index) => (
                <div key={index} className="corporate-numbers__item">
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <TextImage content={textImageContent} isButtonArrow={true} />
        <div className="section corporate-attestation">
          <div className="section-wrapper corporate-attestation-wrapper">
            <div className="corporate-attestation__content">
              <h2>Real-Time Reserves Attestation</h2>
              <p>
                To provide full transparency, GrowMiner&apos;s reserves are verified by an independent third party – a
                PCAOB-certified auditor and a leading US accounting firm. GrowMiner pioneered this real-time attestation
                back in 2021 to show company assets exceed customer liabilities at all times.
              </p>
              <div className="corporate-attestation__logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
            <div className="corporate-attestation-container">
              {attestationItems.map((el, index) => {
                const ind = index + 1
                return (
                  <div
                    key={index}
                    className="corporate-attestation__item"
                    style={{ justifyContent: ind % 2 === 0 ? "flex-end" : "flex-start" }}
                  >
                    <div
                      className="corporate-attestation__item_inside"
                      style={{
                        backgroundColor: ind % 2 === 0 ? "#FFFFFF" : "#ECF6FF",
                        boxShadow: ind % 2 === 0 ? "0px 20px 35px rgba(13, 51, 86, 0.07)" : "",
                      }}
                    >
                      <h3>{el.title}</h3>
                      <p>{el.text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
            See real-time attestation
          </button>
        </div>
        <div style={{ background: `url("${bgReady}") center/cover no-repeat` }} className="section corporate-text">
          <div className="section-wrapper corporate-text-wrapper">
            <h2>Let’s Get Started</h2>
            <p>
              Apply for a GrowMiner Corporate Account and get your application reviewed — usually within 2 business
              days.
            </p>
            <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
              apply now
            </button>
          </div>
        </div>
      </div>
      <Footer type="dark" />
    </div>
  )
}
