import "./Video.scss"
import bg from "../../assets/images/affoliate-decor.svg"
import video from "../../assets/images/video-bg.png"
import button from "../../assets/icons/socials/youtube.svg"

export const Video = (): JSX.Element => {
  const items = [
    {
      title: "Join",
      text: "Fill out the <a>affiliate form</a> – it only takes a minute. Once approved, you’ll get a unique link for your audience.",
    },
    {
      title: "Promote",
      text: "Create content for your favorite networks and encourage followers to grow their crypto holdings with GrowMiner.",
    },
    {
      title: "Earn",
      text: "When a user joins via your link, you’ll get paid each time they earn interest, swap crypto, or borrow funds.",
    },
  ]

  return (
    <div className="video">
      <div className="section video_bg" style={{ backgroundImage: `url("${bg}")` }}>
        <div className="section-wrapper video-wrapper">
          <h2>Getting Started Is Easy</h2>
          <p>Become a GrowMiner Affiliate in 3 simple steps.</p>
          <div className="video__container">
            <div className="video__video">
              <img className="video__video-banner" alt="video-banner" src={video} />
              <img className="video__video-button" alt="icon" src={button} />
            </div>
            <div className="video__content">
              {items.map((el, index) => (
                <div key={index} className="video__item">
                  <div className="video__item-index">{index + 1}.</div>
                  <h3>{el.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: el.text ?? "",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="video__decor" />
        </div>
      </div>
    </div>
  )
}
