import {
  Footer,
  Header,
  SecurityList,
  TextImage,
  Features,
  Platform,
  SwitchContent,
  BannerDown,
} from "../../components"

import bg from "../../assets/images/security-text-img.webp"
import bgBanner from "assets/images/security-banner.png"
import textBg from "../../assets/images/security-logos.svg"
import switchImg from "../../assets/images/security-switch.svg"

import img1 from "../../assets/icons/security-features/1.svg"
import img2 from "../../assets/icons/security-features/2.svg"
import img3 from "../../assets/icons/security-features/3.svg"

import "./Security.scss"

export const Security = (): JSX.Element => {
  const textSectionContent = {
    title: "Unleash the Power of Your Crypto",
    buttonText: "Get Started",
    img: bg,
  }

  const textSectionContent2 = {
    title: "Ledger",
    text: "Ledger Vault provides us with institutional-grade self-custody that is accessible 24/7/365 and fully addresses our retail and institutional clients’ needs for instant services. Ledger Vault carries $150M third-party insurance led by the Arch UK Lloyd's of London syndicate.",
    buttonText: "Visit Website",
    img: textBg,
  }

  const switchSectionContent = {
    title: "Get to Know GrowMiner’s Fundamentals-First Model",
    mainText:
      "The five key mechanisms behind our sustainable business model and the successful protection of your assets.",
    subtitle: "Insurance Is Fundamental",
    text: "Through custody partners such as ledger vault and bakk, among others, GrowMiner has a comprehensive network of custody and insurance providers. Collectively, they provide insurance protections on custodial assets via the most prestigious underwriters, including Lloyd’s of London, and Marsh & Аrch.",
    img: switchImg,
    buttons: [
      {
        title: "View Preview",
      },
      {
        title: "Real-Тime Auditing",
      },
      {
        title: "Risk Management",
      },
      {
        title: "Insurance",
      },
      {
        title: "Global Licensing",
      },
    ],
  }

  const bannerContent = {
    title: `GrowMiner’s Strong Fundamentals <br /><span>Safeguard Your Assets</span>`,
    img: bgBanner,
  }

  const featuresContent = [
    {
      title: "Accredited Information Security Management Systems",
      text: "Your personal information is contained behind secured networks and is only accessible to a limited number of persons who are required to keep the information confidential. Аll sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. GrowMiner implements a variety of security measures when users enter, submit, or access their information.",
      img: img1,
    },
    {
      title: "Data Protection and Confidentiality",
      text: "Your personal information is contained behind secured networks and is only accessible to a limited number of persons who are required to keep the information confidential. Аll sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. GrowMiner implements a variety of security measures when users enter, submit, /or access their information.",
      img: img2,
    },
    {
      title: "Accredited Information Security Management Systems",
      text: "ISO/IEC 27001 guarantees that GrowMiner’s security infrastructure is of the highest standard, thus ensuring the safety of the enterprise and minimizing risks for all clients and investors. Being ISO/IEC 27001 certified, GrowMiner is now raising the bar for the entire blockchain space, by utilizing the most rigorous security policies, impeccable risk assessment, data protection.",
      img: img3,
    },
  ]

  return (
    <div className="security">
      <Header type="light" />
      <div className="security-container">
        <BannerDown content={bannerContent} buttonDown={true} />
        <div id="security">
          <SwitchContent content={switchSectionContent} textButtons={false} />
        </div>
        <div className="section security-section_blue">
          <div className="section-wrapper">
            <h2>GrowMiner Works with the Leading Authorities in Custody and Compliance</h2>
          </div>
        </div>
        <div className="security-1">
          <TextImage content={textSectionContent2} downloadButtons={false} isButtonArrow={true} />
        </div>
        <Platform />
        <SecurityList />
        <Features title="More Security Features" content={featuresContent} />
        <div className="security-2">
          <TextImage content={textSectionContent} downloadButtons={true} isButtonArrow={false} />
        </div>
      </div>
      <Footer type="dark" />
    </div>
  )
}
