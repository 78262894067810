import { useEffect, useState } from "react"

import startImg1 from "assets/images/start1.png"
import startImg2 from "assets/images/start2.png"

import "./DesignedWrapperContent.scss"

type Props = {
  order: string
}

export const DesignedContent = ({ order }: Props): JSX.Element => {
  const [image, setImage] = useState<string | null>(null)
  const [title, setTitle] = useState<string | null>(null)
  const [text, setText] = useState<string | null>(null)

  useEffect(() => {
    if (order === "1") {
      setImage(startImg1)
      setTitle("The ultimate destination for becoming a pro.")
      setText(
        "Unlock the full potential of trading on Growminer with aggregated liquidity. Seamlessly place market and limit orders, automate strategies, leverage margin trading, and delve into futures contracts for an unparalleled trading experience.",
      )
    }
    if (order === "2") {
      setImage(startImg2)
      setTitle("The Right Place toBecome a Pro")
      setText(
        "Trade with aggregated liquidity on GrowMiner Pro. Place market and limit orders, execute automated strategies, trade with margin, and open futures contracts.",
      )
    }
  })

  return (
    <div className={`designed-content designed-content_${order}`}>
      <div className="designed-content__text">
        <h2>{title}</h2>
        <p>{text}</p>
        <a href="/prime#contact-us" className="button">
          Start Trading
        </a>
      </div>
      {image && <img alt="decor" src={image} />}
    </div>
  )
}
