import React, { useEffect, useState } from "react"
import cn from "classnames"

import "./Portfolio.scss"
import arrow from "../../assets/icons/arrow-down_white.svg"
import { ReactComponent as Quote } from "assets/icons/quote-icon.svg"
import img1 from "assets/icons/prime/3/1.svg"
import img2 from "assets/icons/prime/3/2.svg"
import img3 from "assets/icons/prime/3/3.svg"

export const Portfolio = (): JSX.Element => {
  const [active, setActive] = useState<number | undefined>(1)

  const blocks = [
    {
      img: img1,
      title:
        "My crypto journey has come a long way thanks to @GrowMiner. Enjoy the wide diversity of the GrowMiner products, and you won’t regret it.",
    },
    {
      img: img2,
      title:
        "My crypto journey has come a long way thanks to @GrowMiner. Enjoy the wide diversity of the GrowMiner products, and you won’t regret it.",
    },
    {
      img: img3,
      title:
        "My crypto journey has come a long way thanks to @GrowMiner. Enjoy the wide diversity of the GrowMiner products, and you won’t regret it.",
    },
  ]

  return (
    <div id="portfolio" className="section portfolio">
      <div className="section-wrapper portfolio-wrapper">
        <h2>GrowMiner Is the World&apos;s Leading Digital Assets Institution</h2>
        <p>
          We are a trusted partner for leading trading firms, hedge funds, family offices, and OTC desks. Combining
          top-tier custody with large-scale lending and financing, deep liquidity, and efficient low-latency execution,
          GrowMiner can help you scale and execute a multitude of trading or investment strategies.
        </p>
        <a style={{ backgroundImage: `url("${arrow}")` }} className="portfolio-button button" href="/about-us">
          More about GrowMiner
        </a>
        <div className="portfolio-content-wrapper">
          <div className="portfolio-content">
            {blocks.map((item, index) => (
              <div
                className={cn("carousel-block", {
                  _active: active === index,
                  next:
                    active === undefined
                      ? index === 1
                      : active === blocks.length - 1
                      ? index === 0
                      : active + 1 === index,
                  prev:
                    active === undefined
                      ? index === blocks.length - 1
                      : active === 0
                      ? index === blocks.length - 1
                      : active - 1 === index,
                  initial: index === 0 && active === undefined,
                })}
                key={index}
              >
                <div className="carousel-block-content">
                  <Quote fill={active === index ? "#424D6B" : "#7C87A8"} />
                  <p>{item.title}</p>
                </div>
                <img alt="logo" src={item.img} className="portfolio-block-content-img" />
              </div>
            ))}
          </div>
        </div>
        <div className="portfolio-dots">
          {[0, 1, 2].map(dot => (
            <button
              key={dot}
              onClick={() => setActive(dot)}
              className={cn("portfolio-dots-item", {
                _active: active === dot,
              })}
            >
              <div className="portfolio-dots-item-content" />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
