import img from "../../assets/images/security-platform.png"

import "./Platform.scss"
import { useEffect, useState } from "react"
import { useMedia } from "use-media"
import { mixins } from "../../utils"
import cn from "classnames"

type IItems = {
  title: string
  text: string
  ind?: number
}

export const Platform = (): JSX.Element => {
  const [evenItems, setEvenItems] = useState<IItems[]>([])
  const [oddItems, setOddItems] = useState<IItems[]>([])

  const isL = useMedia({ maxWidth: mixins.l })

  const items: IItems[] = [
    {
      title: "2FA Authentication",
      text: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which ",
    },
    {
      title: "Biometric Identification",
      text: "Using Fingerprint or Face Recognition",
    },
    {
      title: "24/7 Support",
      text: "Excellent rated Customer Support available through chat or email",
    },
    {
      title: "Whitelisting",
      text: "Manage your crypto addresses or improve the security of your account even further by enabling Address Whitelisting",
    },
  ]

  useEffect(() => {
    const evenArray = items.filter((el, index) => {
      el.ind = index + 1
      return (index + 1) % 2 === 1
    })
    const oddArray = items.filter((el, index) => {
      el.ind = index + 1
      return (index + 1) % 2 === 0
    })

    setEvenItems(evenArray)
    setOddItems(oddArray)
  }, [])

  const contentItem = (title, text, index) => (
    <div className="platform__item">
      <div className="platform__item-index">{index}</div>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  )

  return (
    <div className="section platform">
      <div className="section-wrapper platform-wrapper">
        <h2>Platform Security</h2>
        <div className="platform__items-container">
          {isL ? (
            <>
              {items.map((el, index) => (
                <div
                  key={index}
                  className={cn("platform__item-wrapper", {
                    _even: (index + 1) % 2 === 1,
                    _odd: (index + 1) % 2 === 0,
                  })}
                >
                  {contentItem(el.title, el.text, index + 1)}
                </div>
              ))}
            </>
          ) : (
            <div className="platform__items-container_even">
              {evenItems.map((el, index) => (
                <div key={index} className="platform__item-wrapper">
                  {contentItem(el.title, el.text, el?.ind)}
                </div>
              ))}
            </div>
          )}
          <img className="platform__img" src={img} alt="platform" />
          {!isL && (
            <div className="platform__items-container_odd">
              {oddItems.map((el, index) => (
                <div key={index}>{contentItem(el.title, el.text, el?.ind)}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
