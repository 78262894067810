import "./Partners.scss"
import { useMedia } from "use-media"
import { mixins } from "../../utils"

type Props = {
  content: {
    title?: string
    text?: string
    logos: {
      icon: string
    }[]
  }
  spacer: boolean
}

export const Partners = ({ content, spacer }: Props): JSX.Element => {
  const { title, text, logos } = content
  const isL = useMedia({ maxWidth: mixins.l })

  return (
    <div className="section partners">
      <div className="section-wrapper partners-wrapper">
        {title && <h2>{title}</h2>}
        <p>{text}</p>
        <div className={`partners__container ${spacer && !isL ? "spacer" : ""}`}>
          {spacer && !isL && (
            <div className="partners__container_inner">
              {logos.slice(0, 5).map((el, index) => (
                <div key={index} className="partners__item">
                  <img alt="partner" src={el.icon} />
                </div>
              ))}
            </div>
          )}
          {spacer && !isL && (
            <div className="partners__container_inner">
              {logos.slice(5, 9).map((el, index) => (
                <div key={index} className="partners__item">
                  <img alt="partner" src={el.icon} />
                </div>
              ))}
            </div>
          )}
          {spacer && !isL && (
            <div className="partners__container_inner">
              {logos.slice(9, logos.length).map((el, index) => (
                <div key={index} className="partners__item">
                  <img alt="partner" src={el.icon} />
                </div>
              ))}
            </div>
          )}

          {(!spacer || (spacer && isL)) && (
            <>
              {logos.map((partner, index) => (
                <div key={index} className="partners__item">
                  <img alt="partner" src={partner.icon} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
