import searchIcon from "../../assets/icons/search.svg"

import "./Banner.scss"

type Props = {
  content: {
    title: string
    text?: string
    subtitle?: string
    buttonText?: string
    img: string
    isSearchBar?: boolean
  }
}

export const Banner = ({ content }: Props): JSX.Element => {
  return (
    <div className="section banner" style={{ backgroundImage: `url("${content.img}")` }}>
      <div className="section-wrapper banner-container">
        <h1>{content.title}</h1>
        {content?.subtitle && <h3>{content?.subtitle}</h3>}
        <p
          dangerouslySetInnerHTML={{
            __html: content?.text ?? "",
          }}
        />
        {content?.buttonText && <button type="button">{content.buttonText}</button>}
        {content?.isSearchBar && (
          <input style={{ backgroundImage: `url("${searchIcon}")` }} placeholder="How Can we help?" type="text" />
        )}
      </div>
    </div>
  )
}
