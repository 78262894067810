import {
  Data,
  Footer,
  Header,
} from "components"

import "./Node.scss"
export const Node = (): JSX.Element => {
  return (
    <div className="about">
      <Header type="light" />
      <div className="about-container">
        <Data />
        <Footer type="dark" />
      </div>
    </div>
  )
}
