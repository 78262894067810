import img1 from "../../assets/icons/help-center/1.svg"
import img2 from "../../assets/icons/help-center/2.svg"
import img3 from "../../assets/icons/help-center/3.svg"
import img4 from "../../assets/icons/help-center/4.svg"

import img5 from "../../assets/icons/markets/features1/2.svg"
import img6 from "../../assets/icons/markets/features1/3.svg"
import img7 from "../../assets/icons/markets/features1/4.svg"

import img8 from "../../assets/icons/markets/features2/1.svg"
import img9 from "../../assets/icons/markets/features2/3.svg"
import img10 from "../../assets/icons/markets/features2/4.svg"
import img11 from "../../assets/icons/markets/features2/2.svg"
import img12 from "../../assets/icons/markets/features2/5.svg"

import arrow from "../../assets/icons/arrow-down_white.svg"
import banner from "../../assets/images/banner-help.webp"

import f1 from "../../assets/images/help-center-1.svg"
import f2 from "../../assets/images/help-center-2.svg"

import "./HelpCenter.scss"
import { Banner, CollapseWrapper, Footer, Header, Reminder } from "../../components"
import bgReady from "../../assets/images/reminder-bg.webp"

export const HelpCenter = (): JSX.Element => {
  const faqsContent = [
    {
      title: "How to buy GrowMiner Tokens?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "Get up to 0.5% cashback with every crypto purchase or swap",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "Earn high-yielding annual interest on crypto assets - Explained",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "How to buy crypto with your credit/debit card in a few simple steps?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "What is the GrowMiner Card and what are the benefits?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "How to take out a fiat loan?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "How to deposit crypto assets?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
    {
      title: "How to create a non-custodial GrowMiner Wallet and Identity?",
      desc: "Lorem ipsum dolor sit amet consectetur. Id risus nunc integer eget mauris ipsum leo sed eu. Gravida eget nisi sit et arcu ultrices et. Pellentesque nunc scelerisque et libero pretium condimentum ultrices. Pulvinar nunc viverra bibendum gravida accumsan. Sit malesuada nunc egestas enim.",
    },
  ]

  const itemsContent = [
    {
      title: "Buy with Card",
      img: img3,
    },
    {
      title: "Getting Started",
      img: img6,
    },
    {
      title: "Loyalty program",
      img: img2,
    },
    {
      title: "Refer a friend",
      img: img1,
    },
    {
      title: "Earn",
      img: img8,
    },
    {
      title: "Borrow",
      img: img9,
    },
    {
      title: "GrowMiner Card",
      img: img10,
    },
    {
      title: "GrowMiner Token",
      img: img11,
    },
    {
      title: "GrowMiner Pro",
      img: img4,
    },
    {
      title: "GrowMiner Booster",
      img: img12,
    },
    {
      title: "Security",
      img: img7,
    },
    {
      title: "Repayment",
      img: img5,
    },
  ]

  const bannerContent = {
    title: "Help Center",
    text: "Looking for Answers? We probably have them right here.",
    isSearchBar: true,
    img: banner,
  }

  const questionsContent = [
    {
      title: "What is GrowMiner?",
      text: "GrowMiner is the new home for your crypto - where you can buy instantly, earn interest, swap coins, and borrow against your assets.",
      img: f1,
    },
    {
      title: "Why GrowMiner?",
      text: "GrowMiner is the new home for your crypto - where you can buy instantly, earn interest, swap coins, and borrow against your assets.",
      img: f2,
    },
  ]

  const reminderContent = {
    title: "Need More Help?",
    text: "Our Support Team is available 24/7 to answer all your questions.",
    buttonText: "Contact Support",
    isDownloadButtons: false,
    banner: bgReady,
  }

  return (
    <div className="help-center">
      <Header type="light" />
      <div className="help-center-container">
        <Banner content={bannerContent} />
        <div className="section help-center-questions">
          <div className="section-wrapper help-center-questions-wrapper">
            <h2>Browse Topics</h2>
            <p>Explore our categories of support articles and FAQs.</p>
            <div className="help-center-questions__container">
              {questionsContent.map((el, index) => (
                <div key={index} className="help-center-questions__item">
                  <img className="help-center-questions__item-img" src={el.img} alt="icon" />
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
                    Get Started
                  </button>
                </div>
              ))}
              <div className="help-center-questions__line" />
            </div>
          </div>
        </div>
        <div className="section help-center-items">
          <div className="section-wrapper help-center-items-wrapper">
            {itemsContent.map((el, index) => (
              <div key={index} className="help-center-items__item">
                <img className="help-center-items__item-img" src={el.img} alt="icon" />
                <h3>{el.title}</h3>
                <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
                  Get Started
                </button>
              </div>
            ))}
          </div>
          <button
            style={{ backgroundImage: `url("${arrow}")` }}
            type="button"
            className="help-center-items-button corporate-button"
          >
            see more
          </button>
        </div>
        <CollapseWrapper
          items={faqsContent}
          title="Frequently Asked Questions"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer diam nulla"
        />
        <Reminder content={reminderContent} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
