import bg from "assets/images/prime-bg.svg"
import bg2 from "assets/images/prime-bg-2.png"
import bg3 from "assets/images/prime-bg3.png"

import img1 from "assets/icons/prime/1/1.svg"
import img2 from "assets/icons/prime/1/2.svg"
import img3 from "assets/icons/prime/1/3.svg"
import img4 from "assets/icons/prime/1/4.svg"

import img5 from "assets/images/prime/1.svg"
import img6 from "assets/images/prime/2.svg"
import img7 from "assets/images/prime/3.svg"
import img8 from "assets/images/prime/4.svg"
import img9 from "assets/images/prime/5.svg"

import img11 from "assets/icons/prime/2/1.svg"
import img12 from "assets/icons/prime/2/2.svg"
import img13 from "assets/icons/prime/2/3.svg"
import img14 from "assets/icons/prime/2/4.svg"

import img21 from "assets/icons/prime/logos/1.svg"
import img22 from "assets/icons/prime/logos/2.svg"
import img23 from "assets/icons/prime/logos/3.svg"
import img24 from "assets/icons/prime/logos/4.svg"
import img25 from "assets/icons/prime/logos/5.svg"
import img26 from "assets/icons/prime/logos/6.svg"
import img27 from "assets/icons/prime/logos/7.svg"
import img28 from "assets/icons/prime/logos/8.svg"
import img29 from "assets/icons/prime/logos/9.svg"
import img210 from "assets/icons/prime/logos/10.svg"
import img211 from "assets/icons/prime/logos/11.svg"
import img212 from "assets/icons/prime/logos/12.svg"

import "./Prime.scss"
import { BannerDown, Features, Footer, Form, Header, Partners, TextListImage } from "../../components"
import { useState } from "react"
import cn from "classnames"
import { Portfolio } from "../../components/Portfolio"

export const Prime = (): JSX.Element => {
  const [activeServe, setActiveServe] = useState(0)

  const bannerContent = {
    title: "Prime Brokerage for Digital Assets",
    subTitle: "GrowMiner PRIME",
    text: "We give institutional clients all the necessary tools to trade, borrow, lend, and securely store digital assets.",
    img: bg,
    buttonText1: "Get Started",
    buttonText2: "watch video",
  }

  const bannerItems = [
    {
      title: "Deep liquiditypool",
      icon: img1,
    },
    {
      title: "Cost-effective execution",
      icon: img2,
    },
    {
      title: "Secure custody by Bakkt,Fireblocks ",
      icon: img3,
    },
    {
      title: "Top-tier insurance viaLloyd’s ",
      icon: img4,
    },
  ]

  const textContent1 = {
    title: "Spot Trading",
    text: "GrowMiner Prime’s best-in-class infrastructure helps you execute large orders smoothly and reliably, with competitive trading fees.",
    img: img5,
    list: [
      "Get cost-effective execution with GrowMiner's Smart Routing System.",
      "Access diversified liquidity, aggregated from the world’s leading exchanges, liquidity providers, and selected market makers.",
      "Trade directly through API calls or use our intuitive trading user interface.",
      "Have access to our 24/7 OTC desk for high-touch executions of large trades.",
    ],
  }

  const textContent2 = {
    title: "Margin & Futures Trading",
    text: "Trade with up to 5x leverage by borrowing at the best market rates. Gain exposure to a range of cryptocurrencies without owning them with a perpetual futures contract.",
    img: img6,
    list: [
      "Scale your trading strategy up to 5x while preserving the value of your crypto assets.",
      "Your entire portfolio is used as collateral, so the good performance of one asset can offset the underperformance of another.",
      "All collateral loans have flexible duration and are powered by our deep liquidity pool.",
      "Amplify your buying or selling power by using up to 20x leverage on futures contracts.",
    ],
  }

  const textContent3 = {
    title: "oneZero Integration",
    text: "The integration provides financial brokers and digital asset exchanges with access to industry-leading liquidity in spot and margin markets.",
    img: img8,
    list: [
      "Deliver pricing and trading over MetaTrader 4 and 5 servers and cross-connections to other bridges globally.",
      "Elevate your business with the comprehensive end-to-end solution, easily set up and fully integrated with GrowMiner Prime.",
      "Benefit from rapid execution speeds and real-time order transmission to minimize the risk of slippage in all conditions.",
      "Еxecute large orders with ease and without disruptions or downtime in high-volume markets.",
    ],
  }

  const textContent4 = {
    title: "Institutional-Grade Custody",
    text: "Our custody framework combines industry-leading solutions from the best in the space: Bakkt, Fireblocks, and Ledger Vault.",
    img: img7,
    list: [
      "All your digital assets are stored in military-grade Class III vaults.",
      "Get instant access to your funds at all times for trading.",
      "Stay protected with our custodians’ comprehensive insurance coverage.",
      "Industry-first, real-time audit of GrowMiner's custodial holdings by Armanino LLP.",
    ],
  }

  const textContent5 = {
    title: "Superior OTC Services",
    text: "Get access to private, personalized services on GrowMiner’s OTC trading, lending, and borrowing desk.",
    img: img9,
    list: [
      "Let our experienced trading team fill your large orders quickly and securely.",
      "Keep your crypto wealth safe, all the while passively earning up to 15% per annum through GrowMiner’s Earn Interest Product.",
      "Borrow a variety of digital currencies via an institutional credit line with flexible settlement and zero interest rates.",
    ],
  }

  const featuresContent = [
    {
      title: "Military-Grade Security",
      text: "Our platform is equipped with a top-tier security infrastructure designed to ensure maximum protection of your assets at all times. In addition.",
      img: img11,
    },
    {
      title: "Full Suite of Services",
      text: "Manage all your financial needs – trading, borrowing, custody, and lending – with a single institution. Go through the onboarding process just once.",
      img: img12,
    },
    {
      title: "Bespoke Lending Solutions",
      text: "GrowMiner's large retail balance and our strong institutional relationships allow us to accommodate any borrowing needs, regardless of the size and duration.",
      img: img13,
    },
    {
      title: "Cost-Effective Execution",
      text: "GrowMiner aggregates liquidity from the biggest spot and derivatives trading venues. Our Smart Order Routing engine provides you with the most cost-effective execution. ",
      img: img14,
    },
  ]

  const serveSectionControls = ["Crypto Exchanges", "Broker Dealers", "Trading Desks", "Payment Providers", "UHNWIs"]

  const partnersSectionContent = {
    text: "We’ve teamed up with the world’s brightest, so we can provide you with the best-in-class services you deserve.",
    logos: [
      {
        icon: img21,
      },
      {
        icon: img22,
      },
      {
        icon: img23,
      },
      {
        icon: img24,
      },
      {
        icon: img25,
      },
      {
        icon: img26,
      },
      {
        icon: img27,
      },
      {
        icon: img28,
      },
      {
        icon: img29,
      },
      {
        icon: img210,
      },
      {
        icon: img211,
      },
      {
        icon: img212,
      },
    ],
  }

  return (
    <div className="prime">
      <Header type="light" />
      <div className="security-container">
        <BannerDown content={bannerContent} buttonDown={false} page="prime" />
        <div className="section prime-banner">
          <div className="section-wrapper prime-banner-wrapper">
            <div className="prime-banner__container">
              {bannerItems.map((el, index) => (
                <div key={index} className="prime-banner__item">
                  <div className="prime-banner__item-img">
                    <img alt="icon" src={el.icon} />
                  </div>
                  <p>{el.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <h2 className="prime-title">Best Execution Price Using Deep & Diversified Liquidity</h2>
        <TextListImage content={textContent1} index="1" />
        <TextListImage content={textContent2} index="2" />
        <TextListImage content={textContent3} index="3" />
        <TextListImage content={textContent4} index="4" />
        <TextListImage content={textContent5} index="5" />
        <Features
          title="Why GrowMiner Prime"
          text="Bespoke services, unlimited capabilities, and safety, combined witha personal touch."
          content={featuresContent}
          button={true}
          buttonText="learn more"
        />
        <div className="section prime-serve">
          <div className="section-wrapper prime-serve-wrapper">
            <h2>Who We Serve</h2>
            <p>Find out how GrowMiner Prime can help you meet your financial needs.</p>
            <div className="prime-serve-controls">
              {serveSectionControls.map((el, index) => (
                <div
                  key={index}
                  className={cn("prime-serve-controls__item", {
                    _active: activeServe === index,
                  })}
                  onClick={() => setActiveServe(index)}
                >
                  {el}
                </div>
              ))}
            </div>
            <div className="prime-serve__content">
              <div>
                <h3>Crypto Exchanges</h3>
                <p>
                  A key differentiator for attracting traders is providing order book depth across a variety of pairs.
                  Onboard once and get access to deep liquidity on 500+ pairs, aggregated from multiple venues.
                </p>
              </div>
              <img alt="serve" src={bg2} />
            </div>
          </div>
        </div>
        <Portfolio />
        <div className="about-partners_1">
          <Partners content={partnersSectionContent} spacer={true} />
        </div>
        <div id="contact-us" className="section prime-form">
          <div className="section-wrapper prime-form-wrapper">
            <div className="prime-form__content">
              <h2>Let’s Get Started</h2>
              <p>Just fill out the form below, and we’ll get back to you within 24 hours.1</p>
              <img alt="serve" src={bg3} />
            </div>
            <Form />
          </div>
        </div>
      </div>
      <Footer type="dark" />
    </div>
  )
}
