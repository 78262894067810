import { DescriptionReferral, Footer, Header } from "components"
import cn from "classnames"
import { ReactComponent as Bg2Icon } from "assets/icons/farm-1.svg"
import { ReactComponent as BgIcon } from "assets/icons/farm-2.svg"
import logoSrc from "assets/images/referral.png"
import "./Referral.scss"

export const Referral = (): JSX.Element => {
  return (
    <div>
      <Header />
      <img src={logoSrc} alt="referral" className="referral-img" />
      <DescriptionReferral />
      <div className={cn("referral separated")}>
        <Bg2Icon className="referral-bg-2" />
        <BgIcon className="referral-bg" />
        <h2 className="referral-title">Rules of Referral Program</h2>
        <div className="referral-table-wrapper">
          <p className="referral-table-wrapper-desc">
            Users can invite friends to sign up and trade on GrowMiner and therefore earn trading commission as rewards.
            Invited friends will receive a welcoming benefit from GrowMiner. User’s percentage of trading commission is
            based on user’s referral level. Details are as follows:
          </p>
          <table>
            <tr>
              <th>Number of activated invitees</th>
              <th>Percentages of Trading Commission</th>
              <th>Levels</th>
            </tr>
            <tr>
              <td>3</td>
              <td>1%</td>
              <td>Beginner</td>
            </tr>
            <tr>
              <td>3-5</td>
              <td>3%</td>
              <td>Junior</td>
            </tr>
            <tr>
              <td>6-8</td>
              <td>6%</td>
              <td>Senior</td>
            </tr>
            <tr>
              <td>9-11</td>
              <td>12%</td>
              <td>Premium</td>
            </tr>
            <tr>
              <td>12-14</td>
              <td>12%</td>
              <td>Leader</td>
            </tr>
            <tr>
              <td>15 or above</td>
              <td>15%</td>
              <td>Partnership</td>
            </tr>
          </table>
        </div>
        <p className="referral-desc">
          User’s commission equation <br />
          Provided interest of invitee x percentage of trading commission = Bonus of user
        </p>
        <ul className="referral-notice">
          Notice
          <li>You’ll receive bonus within 1 working day after the invitees have completed the staking scheme.</li>
          <li>GrowMiner does not allow users to invite themselves. Rewards will be cancelled for any violators.</li>
          <li>
            Rewards will be cancelled for any violations that hijack official traffic or put users at risk of phishing;
            (including but not limited to using content that is highly similar to GrowMiner official social media
            accounts, website content, website domain names for redirection, placing GrowMiner brand word ads through
            search engines, etc.)
          </li>
          <li>GrowMiner reserves the right to adjust the reward rules without prior notice.</li>
        </ul>
      </div>
      <div></div>
      <Footer />
    </div>
  )
}
