import decor from "assets/icons/underline.svg"

import "./SwitchContent.scss"

type Props = {
  content: {
    title?: string
    mainText?: string
    subText?: string
    subtitle: string
    text: string
    img: string
    buttons?: {
      title: string
    }[]
  }
  textButtons: boolean
}

export const SwitchContent = ({ content, textButtons }: Props): JSX.Element => {
  return (
    <div className="section switch">
      <div className="section-wrapper switch-wrapper">
        <div>
          {content?.title && <h2 className="switch__title">{content.title}</h2>}
          {content?.mainText && <p className="switch__text">{content.mainText}</p>}
        </div>
        <div className="switch-buttons">
          {content?.buttons &&
            content.buttons.map((button, index) => (
              <button
                key={index}
                className={`switch-buttons__item 
                ${button.title === "Buy" ? "_active" : ""}
              `}
                type="button"
              >
                {button.title}
              </button>
            ))}
        </div>
        <div className="switch-content">
          <div>
            {content?.subText && (
              <h4>
                {content.subText}
                <img alt="decor" src={decor} />
              </h4>
            )}
            <h2>{content.subtitle}</h2>
            <p>{content.text}</p>
            {textButtons && (
              <div className="switch-content-buttons">
                <a href="/prime#contact-us" className="button switch-content-buttons__item">
                  Buy Now
                </a>
              </div>
            )}
          </div>
          <img alt="digital assets" src={content.img} />
        </div>
      </div>
    </div>
  )
}
