import "./BannerList.scss"
import { ReactChild } from "react"

import decor from "assets/icons/tick-circle.svg"

type Props = {
  content: {
    title?: string
    subTitle?: string
    text?: string
    img: string
    list: string[]
  }
  children?: ReactChild
}

export const BannerList = ({ content, children }: Props): JSX.Element => {
  return (
    <div className="section banner-list">
      <div className="section-wrapper banner-list-wrapper">
        <div className="banner-list__content">
          <h3>{content?.subTitle}</h3>
          <h1>{content?.title}</h1>
          <p>{content?.text}</p>
          <div className="banner-list__list">
            {content?.list.map((el, index) => (
              <div key={index} className="banner-list__item">
                <img alt="decor" src={decor} />
                {el}
              </div>
            ))}
          </div>
          {children}
        </div>
        <img className="banner-list__image" alt="markets" src={content?.img} />
      </div>
    </div>
  )
}
