import { useState } from "react"

import "./Form.scss"

export const Form = (): JSX.Element => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [corporateName, setCorporateName] = useState("")
  const [mail, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [who, setWho] = useState("")
  const [interests, setInterests] = useState("")
  const [message, setMessage] = useState("")

  const sendEmail = (data: any) => {
    fetch("https://public.herotofu.com/v1/bfb070c0-c226-11ec-abfb-2b5c80ae2a8a", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        // Endpoint thinks that it's likely a spam/bot request, you need to change "spam protection mode" to "never" in HeroTofu forms
        if (response.status === 422) {
          throw new Error("Are you robot?")
        }

        if (response.status !== 200) {
          throw new Error(`${response.statusText} (${response.status})`)
        }

        return response.json()
      })
      .then(() => {
        alert("Mail sent!")
      })
      .catch(err => {
        alert("Try again")
      })
  }

  const sendExample = () => {
    // Can be any data, static and dynamic
    sendEmail({
      email: mail,
      name: name,
      phone: phone,
      message: message,
    })
  }

  return (
    <form onSubmit={sendExample} className="form">
      <div className="form-wrapper_double">
        <div className="form-input-wrapper">
          <input
            id="first-name"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value)
            }}
            placeholder="first name"
            className="form-input"
          />
        </div>
        <div className="form-input-wrapper">
          <input
            id="last-name"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value)
            }}
            placeholder="last name"
            className="form-input"
          />
        </div>
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="corporate-name"
          type="text"
          value={corporateName}
          onChange={e => {
            setCorporateName(e.target.value)
          }}
          placeholder="Corporate name"
          className="form-input"
        />
      </div>
      <div className="form-wrapper_double">
        <div className="form-input-wrapper">
          <input
            required
            id="mail"
            type="email"
            value={mail}
            onChange={e => {
              setMail(e.target.value)
            }}
            placeholder="Email"
            className="form-input"
          />
        </div>
        <div className="form-input-wrapper">
          <input
            required
            id="phone"
            type="tel"
            value={phone}
            onChange={e => {
              setPhone(e.target.value)
            }}
            placeholder="Phone"
            className="form-input"
          />
        </div>
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="country"
          type="text"
          value={country}
          onChange={e => {
            setCountry(e.target.value)
          }}
          placeholder="Country"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="who-are-you"
          type="text"
          value={who}
          onChange={e => {
            setWho(e.target.value)
          }}
          placeholder="who are you?"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="interests"
          type="text"
          value={interests}
          onChange={e => {
            setInterests(e.target.value)
          }}
          placeholder="What are you interested in?"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <textarea
          required
          rows={5}
          id="message"
          value={message}
          onChange={e => {
            setMessage(e.target.value)
          }}
          placeholder="Please describe your trading needs:"
          className="form-textarea"
        />
      </div>
      <button style={{ display: "none" }} type="submit" className="form-btn">
        Submit
      </button>
    </form>
  )
}
