import img from "assets/images/security.webp"
import icon from "assets/icons/checked.svg"

import "./SecurityList.scss"

export const SecurityList = (): JSX.Element => {
  const items = [
    "Live Automated Platform",
    "Full Blockchain-Based Smart Contract Security",
    "Extensive Firewall Server Architecture",
    "Fully Audited Smart Contracts",
    "Military-Grade 256-bit Encryption",
    "Regular Third-Party Penetration Testing and Platform Assessment",
  ]

  return (
    <div className="section security-list">
      <div className="section-wrapper security-list-wrapper">
        <img className="security-list__img" alt="mission" src={img} />
        <div className="security-list__container">
          {items.map((el, index) => (
            <div key={index} className="security-list__item">
              <img className="security-list__item-icon" alt="checked" src={icon} />
              <span className="security-list__item-text">{el}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
