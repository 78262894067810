import image from "assets/images/banner_img.svg"
import banner from "../../assets/images/banner.png"

import appStore from "assets/icons/download/app-store.svg"
import playStore from "assets/icons/download/play-store.svg"

import "./Future.scss"
import {hrefs} from "../../utils";

export const Future = (): JSX.Element => {
  const downloadLinks = [
    {
      icon: appStore,
      href: hrefs.apple,
    },
    {
      icon: playStore,
      href: hrefs.googlePlay,
    },
  ]

  return (
    <div
      className="future section"
      style={{
        backgroundImage: `url("${banner}"), linear-gradient(92.81deg, #2D72D1 -6.4%, #122E82 49.81%, #2E70D0 98.42%)`,
      }}
    >
      <div className="section-wrapper future-container">
        <div className="future-left">
          <h1>Purchase and Generate Interest on BTC, ETH, USDT, and Other Cryptocurrencies</h1>
          <p>Maximize your cryptocurrency portfolio&apos;s growth and ensure its security by utilizing Growminer.</p>
          <div className="switch-content-buttons future-buttons">
            <a href={`https://pc.growminer.app`} target='_blank' rel='noreferrer noopener' className="switch-content-buttons__item">Get Started</a>
          </div>
          <div className="footer-links future-links">
            {downloadLinks.map((item, index) => (
              <a target='_blank' rel='noopener noreferrer' className="future-links__item" key={index} href={item.href}>
                <img src={item.icon} alt="download" />
              </a>
            ))}
          </div>
        </div>
        <div className="future-img_hidden" />
        <img src={image} alt="digital assets" className="future-img" />
      </div>
    </div>
  )
}
