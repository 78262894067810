// import { routes } from "./paths"

import {routes} from "./paths";

export const footerLinks = [
  {
    title: "Company",
    links: [
      {
        title: 'About',
        link: routes.about
      },
      {
        title: 'Security',
        link:  routes.security
      },
      {
        title: 'Licenses',
        link:  routes.vulnerability
      },
      // {
      //   title: 'Blog',
      //   link:  routes.blogs
      // },
      {
        title: 'Careers',
        link: routes.corporate
      },
      {
        title: 'Community',
        link:  routes.affiliate
      },
      {
        title: 'Ventures',
        link:  routes.vulnerability
      },
    ],
  },
  {
    title: "About Us",
    links: [
      {
        title: 'Help Center',
        link: routes.helpCenter
      },
      {
        title: 'Contacts',
        link: `${routes.prime}#contact-us`
      },
      // {
      //   title: 'Blog',
      //   link: routes.blogs
      // },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        title: 'Privacy Policy',
        link: routes.vulnerability
      },
      {
        title: 'Terms & Conditions',
        link: routes.terms
      },
      {
        title: 'Services Terms',
        link: routes.cookies
      },
      {
        title: 'Exchange Terms',
        link: routes.terms
      },
      {
        title: 'DeFi Terms',
        link: routes.terms
      },
      {
        title: 'Staking Terms',
        link: routes.terms
      },
      {
        title: 'GrowMiner Card Terms',
        link: routes.terms
      },
    ],
  },
]

export const headerLinks = [
  {
    title: 'Home',
    link: routes.index
  },
  {
    title: 'Market',
    link: routes.markets
  },
  {
    title: 'Corporates',
    link: routes.corporate
  },
  {
    title: 'Resources',
    link: routes.about
  },
  {
    title: 'Security',
    link:  routes.security
  },
  {
    title: 'Node',
    link:  routes.node
  },
  {
    title: 'Company',
    link:  routes.prime
  },

]
