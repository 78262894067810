import {
  Footer,
  Future,
  Header,
  Partners,
  Benefits,
  Clients,
  TextImage,
  DesignedWrapper,
  SwitchContent,
} from "components"

import bg from "assets/images/textImageBg.svg"

import switchImg from "assets/images/buy-img.svg"

import logo1 from "assets/icons/partners/1.svg"
import logo2 from "assets/icons/partners/2.svg"
import logo3 from "assets/icons/partners/3.svg"
import logo4 from "assets/icons/partners/4.svg"
import logo5 from "assets/icons/partners/5.svg"
import logo6 from "assets/icons/partners/6.svg"
import logo7 from "assets/icons/partners/7.svg"
import logo8 from "assets/icons/partners/8.svg"
import logo9 from "assets/icons/partners/9.svg"
import logo10 from "assets/icons/partners/10.svg"

import "./Home.scss"

export const Home = (): JSX.Element => {
  const textSectionContent = {
    title: "Trusted, Award-Winning Digital Asset Platform",
    text: "See all prestigious awards recognizing GrowMiner`s cutting-edge technology, ease of use, and overall mission to serve as a bridge between traditional and digital finance.",
    buttonText: "Learn More",
    img: bg,
  }

  const partnersSectionContent = {
    title: "As Featured in",
    text: "",
    logos: [
      {
        icon: logo1,
      },
      {
        icon: logo2,
      },
      {
        icon: logo3,
      },
      {
        icon: logo4,
      },
      {
        icon: logo5,
      },
      {
        icon: logo6,
      },
      {
        icon: logo7,
      },
      {
        icon: logo8,
      },
      {
        icon: logo9,
      },
      {
        icon: logo10,
      },
    ],
  }

  const switchSectionContent = {
    subText: "THE RIGHT PLACE TO",
    subtitle: "Buy Digital Assets in Seconds",
    text: "Buy 60+ assets with card in seconds. Benefit from instant transactions and up to 0.5% in crypto rewards. Wire transfers are also available for your convenience.",
    img: switchImg,
  }

  return (
    <div className="home">
      <Header type="transparent" />
      <Future />
      {/*<Clients />*/}
      <SwitchContent content={switchSectionContent} textButtons={true} />
      <Benefits />
      <DesignedWrapper order="1" />
      <Partners content={partnersSectionContent} spacer={false} />
      <TextImage content={textSectionContent} />
      <DesignedWrapper order="2" />
      <Footer type="light" />
    </div>
  )
}
