import arrow from "assets/icons/arrow-blue.svg"
import arrowButton from "assets/icons/arrow-blue-right.svg"
import arrowButton2 from "assets/icons/arrow-down_white.svg"
import play from "assets/icons/play-circle.svg"

import el1 from "assets/icons/ellipse-button/el-1.svg"
import el2 from "assets/icons/ellipse-button/el-2.svg"
import el3 from "assets/icons/ellipse-button/el-3.svg"

import "./BannerDown.scss"

type Props = {
  content: {
    title?: string
    subTitle?: string
    text?: string
    buttonText1?: string
    buttonText2?: string
    img: string
  }
  buttonDown?: boolean
  page?: string
}

export const BannerDown = ({ content, buttonDown, page }: Props): JSX.Element => {
  return (
    <div className="section banner-down">
      <div className="section-wrapper banner-down-wrapper">
        <div className="banner-down__content">
          <div>
            <h3>{content?.subTitle}</h3>
            <h1
              dangerouslySetInnerHTML={{
                __html: content?.title ?? "",
              }}
            />
            <p>{content?.text}</p>
            {(content?.buttonText1 || content?.buttonText2) && (
              <div className="banner-down__buttons">
                {content?.buttonText1 && (
                  <a
                    href='https://pc.growminer.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className="banner-down__buttons-item _1"
                    style={{
                      backgroundImage: page === "prime" ? `url("${arrowButton2}")` : "",
                    }}
                  >
                    {content?.buttonText1}
                  </a>
                )}
                {content?.buttonText2 && (
                  <a
                    href='https://youtu.be/qZF6vZdTvPY'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      backgroundImage: page === "prime" ? `url("${play}")` : `url("${arrowButton}")`,
                    }}
                    className="banner-down__buttons-item _arrow _2"
                  >
                    {content?.buttonText2}
                  </a>
                )}
              </div>
            )}
          </div>
          <img alt="security" src={content?.img} />
        </div>
        {buttonDown && (
          <div className="banner-down__swipe">
            <span>See how we keep your assets secure</span>
            <a href="#security">
              <div>
                <img id="el1" alt="security" src={el1} />
                <img id="el2" alt="security" src={el2} />
                <img id="el3" alt="security" src={el3} />
                <img id="arrow" alt="security" src={arrow} />
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
