import { Footer, Header } from "components"

import img1 from "assets/icons/privacy/1.svg"
import img2 from "assets/icons/privacy/2.svg"
import img3 from "assets/icons/privacy/3.svg"
import img4 from "assets/icons/privacy/4.svg"
import { ReactComponent as Outline } from "assets/icons/future-outline.svg"
import "./Privacy.scss"
import "styles/Rules.scss"

export const Privacy = (): JSX.Element => {
  return (
    <div className="privacy">
      <Header />
      <div className="rules">
        <h1 className="rules-title">
          Privacy Policy
          <Outline />
        </h1>
        <div className="rules-block">
          <img src={img1} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Introduction</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              GrowMiner (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the www.triminer.com website and
              the related applications (collectively referred as &quot;Services&quot;). We have prepared this Privacy
              Policy to demonstrate that our policies and practices regarding the collection, use, and disclosure of
              personal data when you use our Services and the choices you have associated with that data.
            </p>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              This Privacy Policy is the integral part of our Term of Service . By using the Service, you agree to the
              collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy
              Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img2} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Information we collect</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              We collect several different types of information for various purposes to provide and improve our Services
              to you. While using our Service, we may ask you to provide us with certain personally identifiable
              information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally
              identifiable information may include, but is not limited to:
              <ul>
                <li>Your personal profile picture;</li>
                <li>Email address;</li>
                <li>Birth date;</li>
                <li>First name and last name;</li>
                <li>Phone number;</li>
                <li>Passport or other identity documents;</li>
              </ul>
            </p>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              If you access our Services through a mobile device, we may be able to collect certain information related
              to your device, including but not limited to: unique identifiers(Android ID, mobile advertising identifier
              ,etc), the general location of your mobile device (not precise geolocation data), your mobile device’s
              brand, model, operating system, resolution, screen size, system version, Bluetooth settings, internet
              connection, random-access memory (“RAM”), the application you have installed, the applications you have
              running in the background.
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img3} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">How we use your personal information</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              We use the collected data for various purposes, including but not limited to the following. If we use your
              personal information in any other ways, we will disclose this to you. If you choose to limit the ways we
              can use your personal information, some or all of the Services may not be available to you.
              <ul>
                <li>To provide and maintain the Services;</li>
                <li>To verify user identity and prevent fraud or unauthorized access;</li>
                <li>To notify you about changes to our Services;</li>
                <li>To allow you to participate in interactive features of our Services when you choose to do so;</li>
                <li>To provide customer contact and support Services;</li>
                <li>To provide analysis or valuable information so that we can improve the Services;</li>
                <li>To monitor the usage of the Services;</li>
                <li>To detect, prevent and address technical issues;</li>
                <li>To deliver you certain material relating to our Service;</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img4} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Disclosure Of Data</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              We may disclose your Personal Data in the good faith belief that such action is necessary to:
              <ul>
                <li>To comply with a legal obligation;</li>
                <li>To protect and defend the rights or property of GrowMiner;</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service;</li>
                <li>To protect the personal safety of users of the Service or the public;</li>
                <li>To protect against legal liability.</li>
              </ul>
            </p>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              With your consent, we may share your personal data with third party for the purpose as prescribed in the
              consent notice. Employees, Third-Party Processors and Third-Party Service Providers. We disclose your
              personal information to our employees, contractors, affiliates, distributors, dealers, vendors and
              suppliers who provide certain services to us or on our behalf, such as operating and supporting the
              Services, analyzing data, or performing marketing or consulting services. These Service Providers will
              only have access to the personal information needed to perform these limited functions on our behalf. In
              the event of a merger, acquisition, bankruptcy or other sale of all or a portion of our assets, any
              personal information owned or controlled by us may be one of the assets transferred to third parties. We
              will notify you via email or a prominent notice within our Services of any change in ownership or uses of
              your personal information, as well as any choices you may have regarding your personal information.  
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
