import founder from "assets/images/founder.webp"
import stars from "assets/icons/stars.svg"

import "./Founder.scss"

export const Founder = (): JSX.Element => {
  return (
    <div className="founder-container">
      <div className="section founder">
        <div className="section-wrapper founder-wrapper">
          <div className="founder__img">
            <img alt="founder" src={founder} />
          </div>
          <div className="founder__content">
            <h2>Creating the Future of Finance Today</h2>
            <p>
            </p>
            <h3>Antoni Trenchev</h3>
            <span>Co-Founder & Managing Partner</span>
            <img className="founder__stars" alt="rating" src={stars} />
          </div>
        </div>
      </div>
    </div>
  )
}
