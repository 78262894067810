import "./TextSection.scss"

export const TextSection = (): JSX.Element => {
  return (
    <div className="section text-section">
      <div className="section-wrapper text-section-wrapper">
        <h2>
          GrowMiner is the world&apos;s leading and most trusted lending institution in the digital finance industry
        </h2>
        <p>
          Since 2018 GrowMiner has strived to bring professional financial services to the world of digital assets.
          Leveraging the best of the team&apos;s years of experience in FinTech along with the power of blockchain
          technology, GrowMiner empowers millions of people to harness the value behind their crypto assets, shaping a
          new, better financial system.
        </p>
      </div>
    </div>
  )
}
