import "./DescriptionReferral.scss"

export const DescriptionReferral = (): JSX.Element => {
  return (
    <div className="description-referral">
      <p className="description-referral-desc">
        Our new Referral Program has been released on Feb 25. By inviting friends to sign up on <span>GrowMiner</span>,{" "}
        <br /> users can unlock mysterious prizes, and the friends invited can also enjoy the benefit from GrowMiner
      </p>
      <h2 className="description-referral-title">What Is GrowMiner Referral Program</h2>
      <p className="description-referral-desc">
        GrowMiner Referral Program is a return to all our loyal users who also recommend GrowMiner to others.
        <br />
        <br />
        The program aims to provide an easier referral system where users can earn bonus through referrals, <br /> the
        more friends that users invited to GrowMiner the higher rewards for the users
      </p>
    </div>
  )
}
