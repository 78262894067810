import { BrowserRouter, Route, Routes } from "react-router-dom"

import {
  NotFound,
  Home,
  ContactUs,
  Download,
  Privacy,
  Terms,
  About,
  FAQ,
  Referral,
  Security,
  Exchanges,
  Blog,
  Corporate,
  Markets,
  HelpCenter,
  Products,
  Cookies,
  Vulnerability,
  MediaCenter,
  Blogs,
  Affiliate,
  Prime, Node,
} from "pages"
import { routes } from "utils"

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<Home />} />
        {/*<Route path={routes.contact} element={<ContactUs />} />*/}
        {/*<Route path={routes.download} element={<Download />} />*/}
        <Route path={routes.privacy} element={<Privacy />} />
        <Route path={routes.terms} element={<Terms />} />
        <Route path={routes.about} element={<About />} />
        {/*<Route path={routes.faq} element={<FAQ />} />*/}
        <Route path={routes.referral} element={<Referral />} />
        <Route path={routes.security} element={<Security />} />
        <Route path={routes.exchanges} element={<Exchanges />} />
        {/*<Route path={routes.blog} element={<Blog />} />*/}
        {/*<Route path={routes.blogs} element={<Blogs />} />*/}
        <Route path={routes.corporate} element={<Corporate />} />
        <Route path={routes.markets} element={<Markets />} />
        <Route path={routes.helpCenter} element={<HelpCenter />} />
        {/*<Route path={routes.products} element={<Products />} />*/}
        <Route path={routes.cookies} element={<Cookies />} />
        <Route path={routes.node} element={<Node />} />
        <Route path={routes.vulnerability} element={<Vulnerability />} />
        <Route path={routes.mediaCenter} element={<MediaCenter />} />
        <Route path={routes.affiliate} element={<Affiliate />} />
        <Route path={routes.prime} element={<Prime />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
