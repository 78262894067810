import "./TextImage.scss"
import appStore from "../../assets/icons/download/app-store.svg"
import playStore from "../../assets/icons/download/play-store.svg"
import arrow from "../../assets/icons/arrow-down_white.svg"
import {hrefs} from "../../utils";

type Props = {
  content: {
    title: string
    text?: string
    buttonText?: string
    buttonLink?: string
    img: string
  }
  downloadButtons?: boolean
  isButtonArrow?: boolean
}

export const TextImage = ({ content, downloadButtons, isButtonArrow }: Props): JSX.Element => {
  const { title, text, img } = content

  const downloadLinks = [
    {
      icon: appStore,
      href: hrefs.apple,
    },
    {
      icon: playStore,
      href: hrefs.googlePlay,
    },
  ]

  return (
    <div className="section text-image">
      <div className="section-wrapper text-image-wrapper">
        <div className="text-image__content">
          <h2>{title}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: text ?? "",
            }}
          />
          {content?.buttonText && (
            <a
              style={{ backgroundImage: `url("${isButtonArrow ? arrow : ""}")` }}
              className="button btn-like-a"
              href={content?.buttonLink || "/"}
            >
              {content?.buttonText}
            </a>
          )}
          {downloadButtons && (
            <div className="footer-links">
              {downloadLinks.map((item, index) => (
                <a target='_blank' rel='noopener noreferrer' className="footer-links__item" key={index} href={item.href}>
                  <img src={item.icon} alt="download" />
                </a>
              ))}
            </div>
          )}
        </div>
        <img className="text-image__image" src={img} alt="background" />
      </div>
    </div>
  )
}
