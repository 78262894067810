import listItem from "../../assets/icons/tick-circle_light.svg"
import "./TextListImage.scss"

type Props = {
  content: {
    title: string
    text: string
    img: string
    list: string[]
  }
  index: string
}

export const TextListImage = ({ content, index }: Props): JSX.Element => {
  return (
    <div className={`section text-list _${index}`}>
      <div className="section-wrapper text-list-wrapper">
        <div className="text-list__content">
          <h3>{content.title}</h3>
          <p>{content.text}</p>
          <div className="text-list__container">
            {content.list.map((el, index) => (
              <div key={index} className="text-list__item">
                <img src={listItem} alt="icon" />
                <p>{el}</p>
              </div>
            ))}
          </div>
        </div>
        <img src={content.img} alt="bg" className="text-list__image" />
      </div>
    </div>
  )
}
