import "./LogosSlider.scss"
import { useEffect, useState } from "react"
import { Carousel } from "./Carousel"
import { useMedia } from "use-media"
import { mixins } from "../../utils"

type Props = {
  content: {
    text?: string
    logos: {
      icon: string
    }[]
  }
}

export const LogosSlider = ({ content }: Props): JSX.Element => {
  const { text, logos } = content
  const [slides, setSlides] = useState<{ icon: string }[][]>([])
  const [slidesPerPage, setSlidesPerPage] = useState<number>(6)

  const isXl = useMedia({ maxWidth: mixins.xl })
  const isM = useMedia({ maxWidth: mixins.m })
  const isS = useMedia({ maxWidth: mixins.s })

  useEffect(() => {
    if (slidesPerPage) {
      const subarray: { icon: string }[][] = []
      for (let i = 0; i < Math.ceil(logos.length / slidesPerPage); i++) {
        // @ts-ignore
        subarray[i] = logos.slice(i * slidesPerPage, i * slidesPerPage + slidesPerPage)
      }
      setSlides(subarray)
    }
  }, [slidesPerPage])

  useEffect(() => {
    if (isXl) {
      setSlidesPerPage(3)
    }
  }, [isXl])

  useEffect(() => {
    if (isM) {
      setSlidesPerPage(2)
    }
  }, [isM])

  useEffect(() => {
    if (isS) {
      setSlidesPerPage(1)
    }
  }, [isS])

  const swiperItems = slides.map((item, index) => (
    <div key={index} className="logos-slider__sub-item">
      {item.map((el, ind) => (
        <div key={ind}>
          <img alt="logo" src={el.icon} />
        </div>
      ))}
    </div>
  ))

  return (
    <div className="section logos-slider">
      <div className="section-wrapper logos-slider-wrapper">
        <p>{text}</p>
        <div className="logos-slider__slider">
          <Carousel>{swiperItems}</Carousel>
        </div>
      </div>
    </div>
  )
}
