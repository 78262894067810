import bg from "assets/images/markets-bg.svg"
import decor from "assets/icons/line-gradient.svg"

import "./HowTo.scss"

export const HowTo = (): JSX.Element => {
  const items = [
    {
      title: "Select Payment Option",
      text: 'Select the "Add Funds" option on your Dashboard and tap on "Credit/Debit Card".',
    },
    {
      title: "Enter Your Details",
      text: "Select Bitcoin and enter your credit or debit card details.",
    },
    {
      title: "Complete the Purchase",
      text: "Preview your transaction details and confirm the purchase. It's that easy!\n",
    },
  ]

  return (
    <div className="section how-to">
      <div className="section-wrapper how-to-wrapper">
        <h2>How to Buy Bitcoin</h2>
        <p>Once you open your GrowMiner account and verify your identity, then you have to:</p>
        <div className="how-to__content">
          <img className="how-to__image" alt="markets" src={bg} />
          <div className="how-to__list">
            {items.map((el, index) => {
              const ind = index + 1
              return (
                <div key={index} className="how-to__item">
                  <div className="how-to__index-wrapper">
                    <div className="how-to__index">{ind}.</div>
                    {ind !== items.length && <img alt="markets" src={decor} className="how-to__item-decor _1" />}
                  </div>
                  <div>
                    <h3>{el.title}</h3>
                    <p>{el.text}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
