import { Footer, Header } from "components"

import img1 from "assets/icons/privacy/1.svg"
import img2 from "assets/icons/terms/1.svg"
import img3 from "assets/icons/terms/2.svg"
import img4 from "assets/icons/terms/3.svg"
import "./Terms.scss"
import "styles/Rules.scss"
import { ReactComponent as Outline } from "assets/icons/future-outline.svg"

export const Terms = (): JSX.Element => {
  return (
    <div className="terms">
      <Header />
      <div className="rules">
        <h1 className="rules-title">
          Terms and Conditions
          <Outline className="second" />
        </h1>
        <div className="rules-block">
          <img src={img1} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Agreement and Execution</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              The content of this agreement includes the main body of this agreement and various rules that have been
              posted or may be posted from time to time by GrowMiner. All of the rules shall be an integral part of this
              agreement and shall have the same legal effect as the main body of this agreement. Unless otherwise
              expressly provided, any service provided by GrowMiner and its affiliates (hereinafter referred to as
              “GrowMiner Service”) shall be bound by this agreement. In case of any inconsistency between the relevant
              terms of service agreement of the specific GrowMiner Services and the contents of this agreement, the
              respective applicable terms of such specific GrowMiner Service shall prevail. You shall carefully read
              through this agreement before using any GrowMiner Service, and pay close attention to the content written
              in bold font. You may consult GrowMiner if you have any questions with regard to this agreement. However,
              regardless of whether you have carefully read through this agreement before using GrowMiner Service, you
              shall be bound by this agreement as long as you use GrowMiner Service. You shall not claim to void or
              rescind this agreement on the ground that you did not read this agreement or you did not receive any
              response from GrowMiner to your consultation. You hereby promise to accept and observe this agreement. If
              you do not agree to this agreement, you shall immediately stop registration/activation or stop using
              GrowMiner Service. GrowMiner may make or amend this agreement and various rules from time to time as needed
              without any individual notice to you. The amended agreement and rules shall come into effect immediately
              and automatically upon being announced on the website. If you do not agree to the relevant amendment, you
              shall immediately stop using GrowMiner Service. If you continue using GrowMiner Service, you shall be deemed
              as having accepted the amended agreement and rules. GrowMiner reserves interpretation rights to all of the
              terms hereof.
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img2} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Registration and Account</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              You shall be bound by this agreement once you have filled in information, read and agreed to this
              agreement, and completed the registration process following the instructions on the registration page or
              you have filled in information, read and agreed to this agreement and completed the activation process
              following the instructions on the activation page, or upon your actual use of GrowMiner Service in a way
              permitted by GrowMiner. You may log in to GrowMiner by your email address or mobile number that you have
              provided or confirmed or by any other means permitted by GrowMiner. You must provide your real name, ID
              type, ID number, and other information required by the laws and regulations. If any information you have
              provided during the registration is inaccurate, GrowMiner will not take any responsibility and any loss,
              direct or indirect, and adverse consequence resulting therefrom will be borne by you. GrowMiner accounts
              can only be used by the person whose name they are registered under. GrowMiner reserves the right to
              suspend, freeze, or cancel accounts that are used by persons other than the persons whose names the
              accounts are registered under. GrowMiner will also not take legal responsibility for these accounts.
              Considering the platform risks, maintenance expenses, and other internal control reasons, GrowMiner has the
              right to cancel or permanently freeze the registered accounts with no transaction for a certain period.  
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img3} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Account Security</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              You shall be solely responsible for the safe keeping of your GrowMiner account and password on your own,
              and you shall be responsible for all activities under your log-in email, GrowMiner account, and password
              (including but not limited to information disclosure, information posting, consent to or submission of
              various rules and agreements by clicking on the website, online renewal of agreement or online purchase of
              services, etc.). You hereby agree that: a) you will notify GrowMiner immediately if you are aware of any
              unauthorized use of your GrowMiner account and password by any person or any other violations of the
              security rules; b) you will strictly observe the security, authentication, dealing, charging, withdrawal
              mechanism or procedures of the website/service; and c) you will log out the website by taking proper steps
              at the end of every visit. GrowMiner shall not and will not be responsible for any loss caused by your
              failure to comply with this provision. You understand that GrowMiner needs reasonable time to take action
              upon your request, and GrowMiner will not undertake any responsibility for the consequences (including but
              not limited to any of your loss) that have occurred prior to such actions.
            </p>
          </div>
        </div>
        <div className="rules-block">
          <img src={img4} alt="privacy" className="rules-block-img" />
          <div className="rules-block-text">
            <h2 className="rules-subtitle">Prohibition of Use</h2>
            <p className="rules-desc">
              <div className="rules-desc-circle" />
              <div className="rules-desc-line" />
              By accessing and using GrowMiner services, you represent and warrant that you have not been included in
              any trade embargoes or economic sanctions list. GrowMiner reserves the right to choose markets and
              jurisdictions to conduct business and offer services and may restrict to refuse, at its discretion, the
              provision of GrowMiner services in certain countries or regions. GrowMiner does not provide services to
              users in particular regions: the United States, North Korea, Iran, China Mainland, Japan, Korea, Ontario
              (Canada). Additionally, GrowMiner does not offer services in certain countries or regions restricted by the
              U.S. Department of Treasury&apos;s Office of Foreign Assets Control (&apos;OFAC&apos;). The users from
              OFAC-sanctioned countries or regions are forbidden to use Triminerservices, Iran, North Korea Sanctions,
              Ukraine, Russia, Cuba, Syria, etc.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
