import icon from "../../assets/icons/bitcoin.svg"

import "./Earning.scss"
import { useState } from "react"

export const Earning = (): JSX.Element => {
  const [result, setResult] = useState("3,390")

  const items = [
    "<span>10%</span> of the earned interest by each invited user within 12 months",
    "<span>20%</span> after an invited user makes 3 purchases with the GrowMiner Card*",
    "<span>0.2%</span> of the swap volume by each invited user within 12 months",
    "<span>30%</span> of spot trading fees on GrowMiner Pro of еach invited user within 12 months",
    "<span>1.0%</span> of the borrowed funds of each invited user within 12 months",
  ]

  const formItems = [
    {
      label: "Audience size",
      defaultValue: "100,000",
    },
    {
      label: "Expected CTR",
      defaultValue: "10%",
    },
    {
      label: "Expected conversion rate",
      defaultValue: "5%",
    },
  ]

  return (
    <div className="section earning">
      <div className="section-wrapper earning-wrapper">
        <div className="earning__container">
          {items.map((el, index) => (
            <div
              key={index}
              className={`earning__item _${index}`}
              dangerouslySetInnerHTML={{
                __html: el ?? "",
              }}
            />
          ))}
          <div className="earning__item_centered">
            <h2> What’s in It for You?</h2>
            <p>
              Make money each time an invited user earns interest, makes а swap, borrows funds and pays with their
              GrowMiner Card.
            </p>
          </div>
        </div>
        {/*<div className="earning-form">*/}
        {/*  <h3>Project Your Earnings</h3>*/}
        {/*  <div className="earning-form__container">*/}
        {/*    {formItems.map((el, index) => (*/}
        {/*      <div key={index} className="earning-form__item">*/}
        {/*        {el.label}*/}
        {/*        <input type="text" defaultValue={el.defaultValue} />*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  <div className="earning-form__result-wrapper">*/}
        {/*    <div className="earning-form__result-content">*/}
        {/*      <div className="earning-form__result">*/}
        {/*        ${result}*/}
        {/*        <span>**</span>*/}
        {/*      </div>*/}
        {/*      <p>per month</p>*/}
        {/*      <button type="button">*/}
        {/*        Become  an affiliate*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <img alt="bitcoin" src={icon} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
