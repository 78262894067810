import decor from "assets/icons/decor.svg"
import bgDecor from "assets/images/bg-decor.png"

import "./DesignedWrapper.scss"
import { DesignedContent } from "./DesignedWrapperContent"

type Props = {
  order: string
}

export const DesignedWrapper = ({ order }: Props): JSX.Element => {
  return (
    <div className="section designed-wrapper">
      <div className="section-wrapper designed-wrapper-container">
        <img alt="decor" src={decor} id="decor-top" className="designed-wrapper-container__decor" />
        <img alt="decor" src={decor} id="decor-bottom" className="designed-wrapper-container__decor" />
        <div style={{ backgroundImage: `url("${bgDecor}")` }} className="designed-wrapper__content">
          <DesignedContent order={order} />
        </div>
      </div>
    </div>
  )
}
