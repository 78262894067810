import bg from "assets/images/affiliate_bg.svg"
import bg2 from "assets/images/affiliate-text.svg"
import bg3 from "assets/images/affiliate-list.png"

import img1 from "../../assets/icons/affiliate/1.svg"
import img2 from "../../assets/icons/affiliate/2.svg"
import img3 from "../../assets/icons/affiliate/3.svg"
import img4 from "../../assets/icons/affiliate/4.svg"
import img5 from "../../assets/icons/affiliate/5.svg"
import img6 from "../../assets/icons/affiliate/6.svg"

import arrow from "../../assets/icons/arrow-down_white.svg"
import listItem from "../../assets/icons/tick-circle_white.svg"

import { BannerDown, CollapseWrapper, Earning, Footer, Header, TextImage, Video } from "../../components"

import "./Affiliate.scss"

export const Affiliate = (): JSX.Element => {
  const bannerContent = {
    title: "Earn Commissions for Each Active Client You Bring",
    text: "Introduce GrowMiner’s suite of products to your audience and earn a steady stream of income.",
    subTitle: "AFFILIATE PROGRAM",
    buttonText1: "Become  an affiliate",
    img: bg,
  }

  const textSectionContent = {
    title: "Join Our Affiliate Program",
    text: "Help your audience unlock the power of their crypto and earn ongoing passive income.",
    buttonText: "Become  an affiliate",
    img: bg2,
  }

  const listItems = [
    "Social media influencers",
    "Bloggers and podcast hosts",
    "Crypto community leaders",
    "Crypto media websites",
  ]

  const items = [
    {
      title: "No Cap, No Limits",
      text: "The more new users you introduce to GrowMiner, the more revenue you’ll earn.",
      img: img1,
    },
    {
      title: "Real-Time Reporting",
      text: "Track the earnings generated by your unique links from a single dashboard.",
      img: img2,
    },
    {
      title: "Long Cookie Duration",
      text: "Earn commissions for users who register up to 30 days after they click on your link.",
      img: img3,
    },
    {
      title: "12-Month Window",
      text: "Get a share of the earned interest, exchange volume and borrowed amount from each customer for a full year!",
      img: img4,
    },
    {
      title: "Dedicated Support",
      text: "Receive support from your dedicated partnership manager. We’re here for you!",
      img: img5,
    },
    {
      title: "Marketing Materials",
      text: "Get attractive banners and optimized landing pages so you can start right away.",
      img: img6,
    },
  ]

  const faqsContent = [
    {
      title: "Are there any requirements to become a GrowMiner Affiliate?",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
    {
      title: "How long does it take for my application to be approved?",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
    {
      title: "How is the commission calculated and when will I get paid? ",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
    {
      title: "Where can I track my earnings?",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
    {
      title: "How should I get started?",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
    {
      title: "Are there any prohibited marketing methods?",
      desc: "Applications are reviewed only during workdays and are usually approved within hours. You’ll receive a confirmation email once you get the green light.",
    },
  ]

  return (
    <div className="affiliate">
      <Header type="light" />
      <div className="security-container">
        <BannerDown content={bannerContent} buttonDown={false} />
        <Earning />
        <Video />
        <div className="section affiliate-items">
          <div className="section-wrapper affiliate-items-wrapper">
            {items.map((el, index) => (
              <div key={index} className="affiliate-items__item">
                <div className="affiliate-items__item-img">
                  <img alt="icon" src={el.img} />
                </div>
                <h3>{el.title}</h3>
                <p>{el.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="section affiliate-list">
          <div className="section-wrapper affiliate-list-wrapper">
            <div className="affiliate-list__content">
              <h2>Who Are We Looking for?</h2>
              <div className="affiliate-list__container">
                {listItems.map((el, index) => (
                  <div key={index} className="affiliate-list__item">
                    <img alt="icon" src={listItem} />
                    {el}
                  </div>
                ))}
              </div>
              <button style={{ backgroundImage: `url("${arrow}")` }} type="button" className="corporate-button">
                Become an affiliate
              </button>
            </div>
            <img className="affiliate-list__image" alt="affiliate" src={bg3} />
          </div>
        </div>
        <CollapseWrapper items={faqsContent} />
        <TextImage content={textSectionContent} downloadButtons={true} isButtonArrow={true} />
      </div>
      <Footer type="dark" />
    </div>
  )
}
