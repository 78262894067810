import arrow from "../../assets/icons/arrow-down_white.svg"
import appStore from "../../assets/icons/download/app-store.svg"
import playStore from "../../assets/icons/download/play-store.svg"

import "./Reminder.scss"
import {hrefs} from "../../utils";

type Props = {
  content: {
    title: string
    text?: string
    buttonText?: string
    banner: string
    isDownloadButtons?: boolean
  }
  removeArrow?: boolean
}

export const Reminder = ({ content, removeArrow }: Props): JSX.Element => {
  const downloadLinks = [
    {
      icon: appStore,
      href: hrefs.apple,
    },
    {
      icon: playStore,
      href: hrefs.googlePlay,
    },
  ]
  return (
    <div style={{ background: `url("${content.banner}") center/cover no-repeat` }} className="section reminder">
      <div className="section-wrapper reminder-wrapper">
        <h2>{content.title}</h2>
        <p>{content?.text}</p>
        {content?.buttonText && (
          <button
            style={{ backgroundImage: !removeArrow ? `url("${arrow}")` : "" }}
            type="button"
            className="reminder-button"
          >
            {content?.buttonText}
          </button>
        )}
        {content?.isDownloadButtons && (
          <div className="exchanges-download-links">
            {downloadLinks.map((item, index) => (
              <a target='_blank' rel='noopener noreferrer' key={index} href={item.href}>
                <img className="exchanges-download-links__item" src={item.icon} alt="download" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
